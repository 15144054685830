import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withRouter } from "react-router-dom"
import { countBy, isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Dropzone from "react-dropzone"
import { withTranslation } from "react-i18next"
import maintanence from "../../../assets/images/coming-soon.svg"
import toastr from "toastr";
import toast, { Toaster } from 'react-hot-toast';


import
paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"
import * as moment from 'moment';
import Select from "react-select"
import jsPDF from "jspdf";
import "jspdf-autotable";
import Moment from 'moment';
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, InputGroup, Input, FormGroup, Label, Button, Card, CardBody, Col, Table, ModalFooter, Container, Row, Modal, Badge, ModalHeader, ModalBody } from "reactstrap"


// Add the Firebase products that you want to use
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"

import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  getRepair,
  addNewRepair,
  updateRepair,
  deleteRepair,
  getVehicle,
  getSupplier,
  updateVehicle,
  getReservation
} from "store/actions"


import exportFromJSON from 'export-from-json'
import { isThisExpression } from "@babel/types";
import { vehicle12 } from "assets/images/vehicle";

class Fines extends Component {
  constructor(props) {
    super(props)
    this.state = {
      p_loading:false,
      modalDenied:false,
      finesPermissions:null,
      account_user: [],
      user_id: "",
      selectedPeriod: "day",
      selectedVehicleName: "All Vehicles",
      selectedVehicle1: 1,
      selectedDate: "",
      selectedTS: null,
      selectedOption: "todo",
      modal1: false,
      currentYear: new Date().getFullYear(),
      SD: new Date(),
      ED: new Date().setDate(new Date().getDate() + 1),
      SDChanged: false,
      EDChanged: false,
      ibadl: false,
      loading: true,
      submitLoading: false,
      isShow: false,
      changed: false,
      vcchanged: false,
      wschanged: false,
      gender: "",
      viewmodal: false,
      modal: false,
      isDelete: false,
      no_data: false,
      success_dlg: false,
      dynamic_title: '',
      dynamic_description: '',
      vc: "",
      p: false,
      selectedVehicle: null,
      vehicles: [],
      vehList: [],
      fines: [],
      fine: [],
      staff: [],
      clients: [],
      reqNext: false,
      FineColumns: [

        {
          dataField: "driver",
          text: this.props.t("Driver"),
          sort: true,
          formatter: (cellContent, row) => (
            <Link to="#" className="text-body fw-bold">
              {row.driver}
            </Link>
          ),
        },
        {
          dataField: "createdAt",
          text: this.props.t("Date created"),
          sort: true,
          formatter: (cellContent, row) => (
            <>

              {" "}
              {moment(row.createdAt).format('YYYY-MM-DDTHH:mm')}
            </>
          ),
        },
        {
          dataField: "vehicle",
          text: this.props.t("Vehicle"),
          sort: true,
        },
        {
          dataField: "date",
          text: this.props.t("Date"),
          sort: true,
        },


        {
          dataField: "amount",
          text: this.props.t("Total amount"),
          sort: true,
          formatter: (cellContent, row) => (
            <>
              {" "}
              {row.amount} dh
            </>
          ),
        },
        /*{
          dataField: "status",
          isDummyField: true,
          text: this.props.t("Status"),
          sort: true,
          formatter: (cellContent, row) => (

            <Badge
              className={row.status == true ? "font-size-12 badge-soft-success" : "font-size-12 badge-soft-warning"}
              color={row.status}
              pill
            >
              {row.status ? "Paid" : "Not paid"}

            </Badge>
          ),
        }, */
        {
          dataField: "view",
          isDummyField: true,
          text: this.props.t("View Details"),
          sort: true,
          formatter: (cellContent, fine) => (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => this.handleViewClick(fine)}
            >
              {this.props.t("View Details")}
            </Button>
          ),
        },
        {
          dataField: "action",
          isDummyField: true,
          text: this.props.t("Action"),
          formatter: (cellContent, fine) => (
            <>

              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => this.handleFineClick(fine)} />
                </Link>
                <Link to="#" className="text-danger">
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.handleDeleteClick(fine)} />
                </Link>
              </div>
            </>
          ),
        },
      ],
      selectedFiles: []
    }
    this.toggleDenied = this.toggleDenied.bind(this)
    this.handleFineClick = this.handleFineClick.bind(this)
    this.toggle = this.toggle.bind(this)
    this.handleValidFineSubmit = this.handleValidFineSubmit.bind(this)
    this.handleFineClicks = this.handleFineClicks.bind(this)
    this.toLowerCase1 = this.toLowerCase1.bind(this)
    this.toggleDelete = this.toggleDelete.bind(this)
    this.handleViewClick = this.handleViewClick.bind(this)
    this.handleSelectVehicle = this.handleSelectVehicle.bind(this)
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
    this.toggleIsShow = this.toggleIsShow.bind(this)
    this.toggle_modal = this.toggle_modal.bind(this)
    this.onValueChange = this.onValueChange.bind(this)
    this.handleValidEventSubmit = this.handleValidEventSubmit.bind(this)
    this.handleSelectVehicleFilter = this.handleSelectVehicleFilter.bind(this)
  }
  toggleDenied() {
    this.setState(prevState => ({
      modalDenied: !prevState.modalDenied,
    }))
  }
  handleDeleteClick = (fine) => {

    const { finesPermissions, p_loaded } = this.state;

    if (!p_loaded) {
      return; // Exit early if permissions are not yet loaded
    }

    if (finesPermissions === null || finesPermissions.delete) {
      this.setState({ isDelete:true, vc: fine });
    } else {
      this.setState({ modalDenied: true }, () => {
        console.log("Permission denied");
      });
    }

  };
  onValueChange(event) {
    const { notifs } = this.state
    this.setState({
      selectedOption: event.target.id
    });
    ////console.log(event.target.id)

  }
  dateChanged = d => {
    /*  const { vehicles, rdchanged, RD } = this.state
     this.setState({ PD: d.target.value })*/
    this.setState({
      selectedTS: moment(d.target.value, 'YYYY-MM-DD').valueOf(),
      selectedDate: d.target.value,
      selectedOption: "dia"
    })

    //console.log(d.target.value)
  }
  monthChanged = d => {

    this.setState({
      selectedTS: moment(d.target.value, 'YYYY-MM').valueOf(),
      selectedDate: d.target.value,
      selectedOption: "mes"
    })

    //console.log(d.target.value)
  }
  toggle_modal() {
    this.setState(prevState => ({
      modal1: !prevState.modal1,
    }))
    this.removeBodyCss()
  }

  removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  handlePeriodChange = (e) => {
    this.setState({
      selectedPeriod: e.target.value
    })
    //console.log(e.target.value);
  };
  handleValidEventSubmit = (e, values) => {

    //console.log(values)
    if (values.period === "day") {
      this.setState({
        selectedTS: moment(values.date, 'YYYY-MM-DD').valueOf(),
        selectedOption: "dia",
        selectedDate: values.date,
      })
    }
    if (values.period === "month") {
      this.setState({
        selectedTS: moment(values.month, 'YYYY-MM').valueOf(),
        selectedOption: "mes",
        selectedDate: values.month,
      })
    }
    if (values.period === "year") {
      this.setState({
        selectedTS: moment(values.year, 'YYYY').valueOf(),
        selectedOption: "ano",
        selectedDate: values.year,
      })
    }



    this.toggle_modal()
  }
  handleSelectVehicleFilter = (selectedVehicle, value) => {
    const { vehicles } = this.state
    /*  let kra = selectedVehicle.target.value
     let plt = kra.substring(kra.indexOf("(") + 1, kra.indexOf(")")) */
    //console.log(value)
    if (value === 'All vehicles') {
      this.setState({

        selectedVehicleName: "All Vehicles",
        selectedVehicle1: 1
      })
    } else {
      const p = vehicles.find(v => v.id === value);
      var plt = ""
      if (p !== undefined) {
        plt = p.plate_number
      }
      let m = 0

      const name = p.make + " " + p.model + " ( " + p.plate_number + " )"
      //console.log(name)
      vehicles.map((e, key) => {

        if (e.plate_number === plt) {
          m = e.mileage
        }
      })
      this.setState({
        mile: m,
        selectedVehicleName: name,
        selectedVehicle1: value
      })
    }

  }
  handleSDChange = SD => {
    const { vehicles, EDChanged, ED, isEdit } = this.state
    this.setState({ SD: SD.target.value })
    this.setState({ SDChanged: true })
    // vehList

  }
  checkChange = p => {
    this.setState({ p: p.target.checked })

  }
  handleEDChange = ED => {
    const { vehicles, SDChanged, SD } = this.state
    this.setState({ ED: ED.target.value })
    this.setState({ EDChanged: true })
    // vehList
  }


  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.setState({ selectedFiles: files })
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  toLowerCase1(str) {
    return str.toLowerCase();
  }
  /* handleSelectType = selectedType => {
    //console.log(selectedType.target.value)
    if (selectedType.target.value === "Oil change" || selectedType.target.value === "Inspection") {

      this.setState({
        reqNext: true
      })
    }else{
      this.setState({
        reqNext: false
      })
    }

  } */
  handleSelectVehicle = selectedVehicle => {

    this.setState({ selectedVehicle: selectedVehicle })
    this.setState({ vcchanged: true })
  }
  /* handleSelectWorkshop = selectedWorkshop => {

    this.setState({ selectedWorkshop: selectedWorkshop })
    this.setState({ wschanged: true })
  } */
  componentDidMount() {
    const { fines } = this.state
    if (fines && !fines.length) {
      // onGetRepair()
      this.getFine()
    }
    this.setState({ fines })
    const { vehicles, onGetVehicle } = this.state
    if (vehicles && !vehicles.length) {
      // onGetVehicle()
      this.getVehicle()
    }
    this.setState({ vehicles })

    const { staff } = this.state
    if (staff && !staff.length) {
      // onGetVehicle()
      this.getStaff()
    }
    this.setState({ staff })
    const { clients } = this.state
    if (clients && !clients.length) {
      // onGetVehicle()
      this.getClients()
    }
    this.setState({ clients })
    this.getfinesPermissions()
  }
  async getfinesPermissions() {
      try {
        this.setState({ p_loaded: false })
        firebase.auth().onAuthStateChanged(async user => {
          if (user) {
            const userId = user.uid;
            // Reference to the user document
            const userDocRef = firebase.firestore().collection("users").doc(userId);
  
            // Fetch the user document
            const userSnapshot = await userDocRef.get();
  
            if (!userSnapshot.exists) {
              this.setState({ p_loaded: true })
              console.log("User document does not exist.");
              return null; // Return null if user document doesn't exist
            }
  
            const userData = userSnapshot.data();
  
            // Check if the user is an admin
            if (userData.isAdmin) {
              this.setState({ p_loaded: true })
              //console.log("User is an admin; permissions are not restricted.");
              return null; // Return null or handle differently for admins
            }
  
            // Reference to the permissions document for 'clients'
            const permissionsDocRef = userDocRef.collection("permissions").doc("traffic_fines");
  
            // Fetch the document
            const permissionsSnapshot = await permissionsDocRef.get();
  
            if (permissionsSnapshot.exists) {
              const finesPermissions = permissionsSnapshot.data();
              /// console.log("Client Permissions:", clientPermissions);
              this.setState({ finesPermissions });
              this.setState({ p_loaded: true })
              return finesPermissions; // Return the permissions as an object
            } else {
              this.setState({ p_loaded: true })
              console.log("No permissions found for 'traffic fines'.");
              return null; // Return null if no permissions exist
            }
          } else {
            this.setState({ p_loaded: true })
            console.log("Not authenthicated")
          }
        })
  
      } catch (error) {
        this.setState({ p_loaded: true })
        console.error("Error fetching traffic fine permissions:", error);
        throw error;
      }
    }
  getFine = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeFines) {
      this.unsubscribeFines();
    }

    this.unsubscribeFines = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userFinesCollectionRef = usersCollection.doc(userId).collection("fines");
            this.setState({
              account_user: userDoc.data()
            })
            this.unsubscribeFines = userFinesCollectionRef.onSnapshot(snapshot => {
              let f = [];
              snapshot.docs.forEach(doc => {
                f.push(doc.data());
              });

              this.setState({
                loading: false,
                fines: f
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            this.setState({
              loading: false,
              fines: []
            });
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          loading: false,
          fines: []
        });
      }
    });

  }

  getVehicle = async () => {

    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeVehicles) {
      this.unsubscribeVehicles();
    }

    this.unsubscribeVehicles = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userVehiclesCollectionRef = usersCollection.doc(userId).collection("vehicles");
            this.setState({
              user_id: userId
            })
            this.unsubscribeVehicles = userVehiclesCollectionRef.onSnapshot(snapshot => {
              let vehicles = [];
              snapshot.docs.forEach(doc => {
                vehicles.push(doc.data());
              });

              this.setState({
                loading: false,
                vehicles: vehicles
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            this.setState({
              loading: false,
              vehicles: []
            });
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          loading: false,
          vehicles: []
        });
      }
    });
  }
  getClients = async () => {

    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeClients) {
      this.unsubscribeClients();
    }

    this.unsubscribeClients = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userClientsCollectionRef = usersCollection.doc(userId).collection("indies");
            this.setState({
              user_id: userId
            })
            this.unsubscribeClients = userClientsCollectionRef.onSnapshot(snapshot => {
              let clients = [];
              snapshot.docs.forEach(doc => {
                clients.push(doc.data());
              });

              this.setState({
                loading: false,
                clients: clients
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            this.setState({
              loading: false,
              clients: []
            });
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          loading: false,
          clients: []
        });
      }
    });
  }
  getStaff = async () => {

    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeStaff) {
      this.unsubscribeStaff();
    }

    this.unsubscribeStaff = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userStaffCollectionRef = usersCollection.doc(userId).collection("staffs");
            this.setState({
              user_id: userId
            })
            this.unsubscribeStaff = userStaffCollectionRef.onSnapshot(snapshot => {
              let staffs = [];
              snapshot.docs.forEach(doc => {
                staffs.push(doc.data());
              });

              this.setState({
                loading: false,
                staff: staffs
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            this.setState({
              loading: false,
              staff: []
            });
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          loading: false,
          staff: []
        });
      }
    });
  }

  // eslint-disable-next-line no-unused-vars
  /* componentDidUpdate(prevProps, prevState, snapshot) {
    const { fines } = this.state
    const { vehicles } = this.state


    if (!isEmpty(fines) && size(prevState.fines) !== size(fines)) {
      // this.setState({ repairs: {}, isEdit: false })
    }
    if (!isEmpty(vehicles) && size(prevProps.vehicles) !== size(vehicles)) {
      // this.setState({ vehicles: {}, isEdit: false })
    }

  } */

  toggle() {
    
      this.setState(prevState => ({
        modal: !prevState.modal,
      }))
    
    
  }
  toggleDelete() {
    this.setState(prevState => ({
      isDelete: !prevState.isDelete,
    }))
  }

  handleFineClicks = () => {
    const { finesPermissions, p_loaded } = this.state;

    if (!p_loaded) {
      return; // Exit early if permissions are not yet loaded
    }

    if (finesPermissions === null || finesPermissions.add) {
      this.setState({ vehList: [], fine: [], isEdit: false, isShow: false, selectedVehicle: null, p: false, selectedFine: null }, () => {
        this.toggle(); // Ensure toggle is called after state updates
      });
    } else {
      this.setState({ modalDenied: true }, () => {
        console.log("Permission denied");
      });
    }
    
  }

  // eslint-disable-next-line no-unused-vars
  handleTableChange = (type, { page, searchText }) => {
    const { fines } = this.state
    this.setState({
      fines: fines.filter(fine =>
        Object.keys(fine).some(
          key =>
            typeof fine[key] === "string" &&
            fine[key].toLowerCase().includes(searchText.toLowerCase())
        )
      ),
    })
  }

  toggleViewModal = () => {
    this.setState(prevState => ({
      viewmodal: !prevState.viewmodal,
    }))
  }

  toggleIsShow = () => {
    this.setState(prevState => ({
      isShow: !prevState.isShow,
    }))
  }


  /* Insert,Update Delete data */

  handleDeleteFine = (fine) => {


    //const { onDeleteRepair } = this.props
    /*  if (repair['code'] === '') {
       //
     } else { */
    this.deleteFine(fine)
    // toast.success(this.props.t("Item Deleted "))
    this.toggleDelete()
    // }

  }

  handleViewClick = arg => {
    const fine = arg
    //this.setState({ selectedType: { label: repair.type, value: repair.type } })
    this.setState({ selectedVehicle: { label: fine.vehicle, value: fine.plate_number } })
    //  this.setState({ selectedWorkshop: { label: repair.workshop, value: repair.workshop_id } })
    this.setState({
      fine: {
        id: fine.id,
        label: fine.label,
        driver: fine.driver,
        vehicle: fine.vehicle,
        plate_number: fine.plate_number,
        date: fine.date,
        payment_date: fine.payment_date,
        pd: fine.pd,
        amount: fine.amount,
        details: fine.details,
        // status: fine.status
      },

      isShow: true,
      isEdit: false

    })

    //this.toggleIsShow()
    this.toggle()

    //this.toggleViewModal()
  }


  handleFineClick = arg => {
    
    const { finesPermissions, p_loaded } = this.state;

    if (!p_loaded) {
      return; // Exit early if permissions are not yet loaded
    }

    if (finesPermissions === null || finesPermissions.update) {
      const fine = arg

    const { vehicles, vehList } = this.state

    const found = vehicles.find(item => item.plate_number === fine.plate_number);
    var tmp = []
    tmp = vehList
    tmp.push(found)


    //this.setState({ selectedType: { label: repair.type, value: repair.type } })
    this.setState({ selectedVehicle: { label: fine.vehicle, value: fine.plate_number } })
    // this.setState({ selectedWorkshop: { label: repair.workshop, value: repair.workshop_id } })
    this.setState({
      fine: {
        id: fine.id,
        label: fine.label,
        vehicle: fine.vehicle,
        driver: fine.driver,
        plate_number: fine.plate_number,
        date: fine.date,
        payment_date: fine.payment_date,
        pd: fine.pd,
        amount: fine.amount,
        details: fine.details,
        // status: fine.status
      },
      isEdit: true,
      isShow: false,
      vehList: tmp
    })
      this.toggle()
    } else {
      this.setState({ modalDenied: true }, () => {
        console.log("Permission denied");
      });
    }
    
  }

  /**
   * Handling submit Order on Order form
   */
  handleValidFineSubmit = (e, values) => {
    // const { onAddNewRepair, onUpdateRepair } = this.state
    //const { onUpdateVehicle } = this.state
    const { isEdit, fines, selectedFine, vehicles } = this.state
    /*  const cds = []
     this.props.repairs.map(spl =>
       cds.push(spl.code)
     )
     const cd =cds.length==0?1:Math.max.apply(null,cds)+1 */

    const p = vehicles.find(v => v.id === values.vehicle);
    var plt = ""
    var vehicle = ""
    if (p !== undefined) {
      plt = p.plate_number
      vehicle = p.make + " (" + p.plate_number + ")"
    }

    var pd = new Date(values.payment_date).getTime()

    //var paid = e.target.paid.checked
    //console.log("paid ", paid)
    var am = parseInt(values.amount)

    if (isEdit) {
      //des=this.state.selectedType.defaultInputValue

      const updateFine = {
        id: this.state.fine.id,
        label: values.label,
        vehicle: values.vehicle,
        driver: values.driver,
        plate_number: plt,
        date: values.date,
        payment_date: values.payment_date,
        pd: pd,
        amount: am,
        details: values.details,
        //status: paid,
      }

      // update Order
      if (this.state.fine.label == updateFine.label &&
        this.state.fine.vehicle == updateFine.vehicle &&
        this.state.fine.driver == updateFine.driver &&
        this.state.fine.date == updateFine.date &&
        this.state.fine.payment_date == updateFine.payment_date &&
        this.state.fine.amount == updateFine.amount &&
        //this.state.fine.status == updateFine.status &&
        this.state.fine.details == updateFine.details) {
        this.setState({ submitLoading: false })
        this.toggle()
      } else {
        //console.log(updateFine)
        this.updateFine(updateFine)
      }
    } else {

      const newFine = {

        label: values["label"],
        vehicle: vehicle,
        driver: values["driver"],
        plate_number: plt,
        v_id: values.vehicle,
        date: values['date'],
        payment_date: values['payment_date'],
        pd: pd,
        amount: am,
        details: values["details"],
        // status: paid
      }
      ////console.log(des)
      // save new Order
      this.setState({ fine: newFine })
      this.addNewFine(newFine)
    }
    this.setState({ selectedfine: null })
    //this.toggle()
  }




  deleteFine = (fine) => {

    ////console.log(tax)
    /* var v_u=[]
    var count=0
     this.state.vehicles.map(function (item) {
       if (item.plate_number === repair.plate_number) {
         if(item.status==="unavailable"){
           
           const ad = Moment(new Date()).format('YYYY-MM-DDTHH:mm')
           
           v_u.push({id:item.id,available_date:ad,start_date:"",status:"available"})
         }
       }
 
     })
     
     //console.log(count,v_u.length)
     if( v_u.length!==0){
       //console.log("dkhel")
       this.updateVehicle(v_u[0])
     }
     */
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("fines")
    const timeline = {
      statusTitle: "Traffic fine deleted",
      iconClass: "bx bx-receipt h2 text-danger",
      description: "Traffic fine information has been deleted for ",
      data: fine.label,
      time: new Date().getTime(),

    }
    collection.doc(fine.id).delete().then(() => {


      //console.log("Element  deleted!");
      toast.success(this.props.t("Traffic fine  deleted!"))
      this.addNewTimeline(timeline)
      this.setState({
        success_dlg: true,
        dynamic_title: this.props.t("Deleted"),
        dynamic_description: this.props.t("Element has been deleted."),
      })
    }).catch((error) => {
      console.error("Error removing element: ", error);
      toast.error(this.props.t("Error removing element"));

    });
  }

  updateFine = async (fine) => {
    const { fines } = this.state
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("fines")
    this.setState({ submitLoading: true })
    const timeline = {
      statusTitle: "Traffic fine updated",
      iconClass: "bx bx-receipt h2 text-warning",
      description: "Traffic fine information has been updated for ",
      data: fine.label,
      time: new Date().getTime(),

    }

    collection.doc(fine.id).update({

      label: fine.label,
      vehicle: fine.vehicle,
      driver: fine.driver,
      plate_number: fine.plate_number,
      date: fine.date,
      payment_date: fine.payment_date,
      pd: fine.pd,
      amount: fine.amount,
      details: fine.details,
      //status: fine.status

    }).then(() => {
      //console.log("Traffic fine  Updated!");
      toast.success(this.props.t("Traffic fine  Updated!"))
      this.addNewTimeline(timeline)
      this.setState({ submitLoading: false })
      this.toggle()
    }).catch((error) => {
      console.error("Error updating fine: ", error);
      toast.error(this.props.t("Error updating fine"))
      this.setState({ submitLoading: false })
    });



  }
  addNewTimeline = async (timeline) => {
    try {
      const { user_id, account_user } = this.state;
      const collection = firebase.firestore().collection("users").doc(user_id).collection("timeline");
      const newDoc = collection.doc();
      const by = account_user.isAdmin ? 'Admin' : `${account_user.first_name} ${account_user.last_name}`;

      await newDoc.set({
        id: newDoc.id,
        statusTitle: timeline.statusTitle,
        iconClass: timeline.iconClass,
        description: timeline.description,
        time: timeline.time,
        data: timeline.data,
        user: by
      });

      //console.log("Timeline  written!");
    } catch (error) {
      console.error("Error adding timeline:", error);
    }
  }

  addNewFine = async (fine) => {
    const { fines } = this.state
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("fines")
    this.setState({ submitLoading: true })
    var newDoc = collection.doc();
    const timeline = {
      statusTitle: "Traffic fine added",
      iconClass: "bx bx-receipt h2 text-success",
      description: "Traffic fine information has been added ",
      data: fine.label,
      time: new Date().getTime(),

    }

    newDoc.set(
      {
        id: newDoc.id,
        label: fine.label,
        vehicle: fine.vehicle,
        driver: fine.driver,
        v_id: fine.v_id,
        plate_number: fine.plate_number,
        date: fine.date,
        payment_date:fine.payment_date,
        pd:fine.pd,
        amount: fine.amount,
        details: fine.details,
        //  status: fine.status,
        createdAt: new Date().getTime(),

      }).then(() => {

        //console.log("Traffic fine  written!");
        toast.success(this.props.t('Traffic fine added '))
        this.addNewTimeline(timeline)
        this.setState({ submitLoading: false })
        this.toggle()
      })
      .catch((error) => {
        console.error("Error Adding Traffic fine infos: ", error);
        toast.error(this.props.t("Error Adding Traffic fine infos"))
        this.setState({ submitLoading: false })
      });



  }


  handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format('DD MMM Y');
    return date1;
  }





  render() {
    // //console.log(this.state.p)
    const emptyDataMessage = () => {
      return <Container>
        <Row>
          <Col lg="12">
            {this.state.loading ?
              <div className="text-center">
                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div>
                      <div >
                        <div className="spinner-chase">
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              :
              <div className="text-center">

                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div className="maintenance-img">
                      <img
                        src={maintanence}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
                <h4 className="mt-5">{this.props.t("Let's get started with Medios")}</h4>
                <p className="text-muted">
                  {this.props.t("Start adding your traffic fine informations by clicking ")}<h5 className="text-primary"><Link onClick={this.handleFineClicks} >{this.props.t("New Traffic fine")}</Link></h5>
                </p>
              </div>
            }
          </Col>
        </Row>
      </Container>
        ;
    }
    const { finesPermissions,staff, clients, currentYear, selectedVehicle1, selectedOption, selectedTS, selectedDate, selectedPeriod, fines } = this.state
    var fns
    fns = fines
    const data = fines;
    //console.log(staff, clients)
    switch (selectedOption) {
      case 'todo':
        var r = []
        fines.map(function (item) {
          if (selectedVehicle1 === 1) {
            r.push(item)
          }
          if (item.v_id === selectedVehicle1) {
            r.push(item)
          }
        });

        fns = r
        break;
      case 'hoy':
        var r = []
        fines.map(function (item) {
          var start = Moment(item.createdAt).format('YYYY-MM-DDThh:mm')
          var pd = new Date(start).getTime()
          var sd = moment(new Date()).format('MM-DD-YYYY')
          var ds = moment(pd).format("MM-DD-YYYY");
          var t = moment(ds)
          var s = moment(sd)
          if (s.isSame(t, 'day') && selectedVehicle1 === 1) {
            r.push(item)
          }
          if (s.isSame(t, 'day') && item.v_id === selectedVehicle1) {
            r.push(item)
          }
        });

        fns = r
        break;
      case 'sempa':
        var r = []
        fines.map(function (item) {
          var start = Moment(item.createdAt).format('YYYY-MM-DDThh:mm')
          var pd = new Date(start).getTime()
          if (moment(new Date().getTime()).isSame(moment(pd), 'week') && selectedVehicle1 === 1) {
            r.push(item)
          }
          if (moment(new Date().getTime()).isSame(moment(pd), 'week') && item.v_id === selectedVehicle1) {
            r.push(item)
          }
        });

        fns = r
        break;
      case 'mespa':
        var r = []
        fines.map(function (item) {
          var start = Moment(item.createdAt).format('YYYY-MM-DDThh:mm')
          var pd = new Date(start).getTime()
          if (moment(new Date().getTime()).isSame(moment(pd), 'month') && selectedVehicle1 === 1) {
            r.push(item)
          }
          if (moment(new Date().getTime()).isSame(moment(pd), 'month') && item.v_id === selectedVehicle1) {
            r.push(item)
          }
        });


        fns = r
        break;
      case 'dia':
        //console.log("diaaaaa")
        var r = []
        fines.map(function (item) {
          var start = Moment(item.createdAt).format('YYYY-MM-DDThh:mm')
          var pd = new Date(start).getTime()
          var sd = moment(selectedTS).format('MM-DD-YYYY')
          var ds = moment(pd).format("MM-DD-YYYY");
          var t = moment(ds)
          var s = moment(sd)
          if (s.isSame(t, 'day') && selectedVehicle1 === 1) {
            r.push(item)
          }
          if (s.isSame(t, 'day') && item.v_id === selectedVehicle1) {
            r.push(item)
          }
        });
        fns = r
        break;
      case 'mes':
        //console.log("meeeees")
        var r = []
        fines.map(function (item) {
          var start = Moment(item.createdAt).format('YYYY-MM-DDThh:mm')
          var pd = new Date(start).getTime()
          if (moment(selectedTS).isSame(moment(pd), 'month') && selectedVehicle1 === 1) {
            r.push(item)
          }
          if (moment(selectedTS).isSame(moment(pd), 'month') && item.v_id === selectedVehicle1) {
            r.push(item)
          }
        });

        fns = r
        break;
      case 'ano':
        //console.log("anooooo")
        var r = []
        fines.map(function (item) {
          var start = Moment(item.createdAt).format('YYYY-MM-DDThh:mm')
          var pd = new Date(start).getTime()
          if (moment(selectedTS).isSame(moment(pd), 'year') && selectedVehicle1 === 1) {
            r.push(item)
          }
          if (moment(selectedTS).isSame(moment(pd), 'year') && item.v_id === selectedVehicle1) {
            r.push(item)
          }
        });


        fns = r
        break;
      default:

    }
    //console.log(fns)
    let dataxcl = []
    fns.map(function (item) {
      delete item.fine;
      dataxcl.push({
        label: item.label,
        vehicle: item.vehicle,
        driver: item.driver,
        plate_number: item.plate_number,
        date: item.date,
        payment_date:item.payment_date,
        amount: item.amount,
        details: item.details,
        // status: item.status
      })
    });

    const { selectedVehicle } = this.state
    const { SearchBar } = Search
    const { isEdit } = this.state
    const { isDelete } = this.state
    const { vc } = this.state
    const { vehicles } = this.state
    const { vehList } = this.state
    //pagination customization
    ////console.log(vehList)
    let drList = []
    let vcList = []
    const vcList1 = []
    const wsList = []
    vcList1.push({ label: "All vehicles", value: "All" })
    vehicles.map(function (item, i) {

      vcList1.push({ id: item.id, label: item.make + "(" + item.plate_number + ")", value: item.plate_number })

    })
    vcList.push({ label: "", value: "" })
    vehicles.map(function (item, i) {
      vcList.push({ id: item.id, label: item.make + ' (' + item.plate_number + ')', value: item.plate_number })

    })

    drList.push({ label: this.props.t("Staff Members"), value: "staff" })
    staff.map(function (item, i) {
      drList.push({ id: item.id, label: item.first_name + " " + item.last_name, value: item.first_name + " " + item.last_name })

    })
    drList.push({ label: this.props.t("Clients"), value: "clients" })
    clients.map(function (item, i) {
      drList.push({ id: item.id, label: item.first_name + " " + item.last_name, value: item.first_name + " " + item.last_name })

    })


    const defaultSorted = [{
      dataField: 'createdAt',
      order: 'desc'
    }];

    const selectRow = {
      mode: 'checkbox',
    };

    /* const optionType =
      [
        { label: "", value: "" },
        { label: this.props.t("Maintenance"), value: "Maintenance" },
        { label: this.props.t("Repair"), value: "Repair" },
        { label: this.props.t("Accident repair"), value: "Accident repair" },
        { label: this.props.t("Oil change"), value: "Oil change" },
        { label: this.props.t("Inspection"), value: "Inspection" },
      ] */

    function ExportToExcel() {
      exportFromJSON({ data: dataxcl, fileName: 'Traffic fines', exportType: exportFromJSON.types.xls })
    }
    function exportPDF() {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape

      const marginLeft = 20;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);

      const title = "Traffic fines Infos";
      const headers = [["label", "Vehicle", "Date", "amount", "Details"]];

      const dt = fns.map(elt => [elt.label, elt.vehicle, elt.date, elt.amount, elt.details]);

      let content = {
        startY: 50,
        head: headers,
        body: dt
      };

      doc.text(title, marginLeft, 20);
      doc.autoTable(content);
      doc.save("Traffic fines.pdf")
    }
    function printPDF() {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape

      const marginLeft = 20;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);

      const title = "Traffic fines Infos";
      const headers = [["label", "Vehicle", "Date", "amount", "Details"]];

      const dt = data.map(elt => [elt.label, elt.vehicle, elt.date, elt.amount, elt.details]);


      let content = {
        startY: 50,
        head: headers,
        body: dt
      };

      doc.text(title, marginLeft, 20);
      doc.autoTable(content);
      var string = doc.output('datauristring');
      var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
      var x = window.open();
      x.document.open();
      x.document.write(embed);
      x.document.close();

    }

    return (

      <React.Fragment>


        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Traffic fines | Medios - Car Rental Management System")}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title={this.props.t("Extras")} breadcrumbItem={this.props.t("Traffic fines")} />
            {this.state.success_dlg ? (
              <SweetAlert
                success
                title={this.state.dynamic_title}
                onConfirm={() => this.setState({ success_dlg: false })}
              >
                {this.state.dynamic_description}
              </SweetAlert>
            ) : null}
            <Row className="mb-2" >

              <Row>

                <Col dm="3" className="text-sm-center mb-2">
                  <AvForm>
                    <AvField
                      name="title"
                      type="select"
                      onChange={this.handleSelectVehicleFilter}
                      validate={{
                        required: { value: true },
                      }}

                    >
                      {vcList1.map((e, key) => {

                        return <option key={key} value={e.id}>{e.label}</option>;

                      })}</AvField>
                  </AvForm>



                </Col>
                <Col md="6" >
                  <div className="text-end " >

                    <Col   >

                      <div className="btn-group me-2" role="group" aria-label="Basic radio toggle button group" onChange={this.onChangeValue}>
                        <input type="radio" className="btn-check" name="btnradio" id="todo" onClick={this.onValueChange} autoComplete="off" defaultChecked />
                        <label className="btn btn-primary" htmlFor="todo">{this.props.t("All")}</label>

                        <input type="radio" className="btn-check" name="btnradio" id="hoy" onClick={this.onValueChange} autoComplete="off" />
                        <label className="btn btn-primary" htmlFor="hoy">{this.props.t("Today")}</label>

                        <input type="radio" className="btn-check" name="btnradio" id="sempa" onClick={this.onValueChange} autoComplete="off" />
                        <label className="btn btn-primary" htmlFor="sempa">{this.props.t("This week")}</label>

                        <input type="radio" className="btn-check" name="btnradio" id="mespa" onClick={this.onValueChange} autoComplete="off" />
                        <label className="btn btn-primary" htmlFor="mespa">{this.props.t("This month")}</label>

                        <button
                          type="button"
                          className="btn btn-light mb-2"
                          onClick={this.toggle_modal}
                        >
                          <i className="bx bx-filter  font-size-22 align-middle "></i>

                        </button>
                      </div>

                    </Col>

                  </div>
                </Col>


                <Modal
                  isOpen={this.state.modal1}
                  toggle={this.toggle_modal}
                  id="event-modal"
                >
                  <ModalHeader toggle={this.toggle_modal} tag="h4">
                    {this.props.t("Custom Period")}
                  </ModalHeader>
                  <ModalBody>
                    <AvForm onValidSubmit={this.handleValidEventSubmit}>
                      <Row form>
                        <Col className="col-12 mb-3">

                          {/* <AvField
  name="title"
  label="Vehicles"
  type="select"
  onChange={this.handleSelectVehicle}
  validate={{
    required: { value: true },
  }}

>
  {vcList.map((e, key) => {

    return <option key={key} value={e.id}>{e.label}</option>;

  })}</AvField> */}
                        </Col>
                        <Col className="col-12 mb-3">
                          <AvField
                            type="select"
                            name="period"
                            label={this.props.t("Select Period")}
                            defaultValue={selectedPeriod}
                            validate={{
                              required: { value: true },
                            }}
                            onChange={this.handlePeriodChange}
                          >
                            <option value="day">{this.props.t("Day")}</option>
                            <option value="month">{this.props.t("Month")}</option>
                            <option value="year">{this.props.t("Year")}</option>
                          </AvField>
                        </Col>

                        {selectedPeriod === "day" ?
                          <div className="mb-3 mt-4 row">
                            <label
                              htmlFor="example-month-input"
                              className="col-md-2 col-form-label"
                            >
                              {this.props.t("Day")}
                            </label>

                            <div className="col">
                              <AvField
                                className="form-control text-sm-start"
                                name="date"
                                type="date"
                                onChange={this.dateChanged}
                                id="example-date-input"
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </div>
                          </div>
                          : null}
                        {selectedPeriod === "month" ?
                          <div className="mb-6 row mb-3">
                            <label
                              htmlFor="example-month-input"
                              className="col-md-2 col-form-label"
                            >
                              {this.props.t("Month")}
                            </label>
                            <div className="col">
                              <AvField
                                name="month"
                                className="form-control"
                                type="month"
                                onChange={this.monthChanged}
                                id="example-month-input"
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </div>
                          </div>
                          : null}
                        {selectedPeriod === "year" ?
                          <div className="mb-3 row mb-3">
                            <AvField
                              type="select"
                              name="year"
                              label="Select year"
                              defaultValue={currentYear}
                              validate={{
                                required: { value: true },
                              }}
                            >
                              <option value={currentYear}>{currentYear}</option>
                              <option value={currentYear - 1}>{currentYear - 1}</option>
                              <option value={currentYear - 2}>{currentYear - 2}</option>
                              <option value={currentYear - 3}>{currentYear - 3}</option>
                              <option value={currentYear - 4}>{currentYear - 4}</option>
                            </AvField>
                          </div>
                          : null}
                      </Row>
                      {/* <h5 className="text-center mt-4">
{this.state.selectedDate || this.state.selectedMonth}
</h5> */}
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-light"
                          data-dismiss="modal"
                          onClick={this.toggle_modal}
                        >
                          {this.props.t("Close")}
                        </button>

                        <button
                          type="submit"
                          className="btn btn-success"
                        >
                          {this.props.t("Confirm")}
                        </button>
                      </div>
                    </AvForm>
                  </ModalBody>


                </Modal>

              </Row>

            </Row>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory()}
                      keyField='id'
                      columns={(this.state.FineColumns || [])}
                      data={(fns || [])}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={fns}
                          columns={(this.state.FineColumns || [])}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2" class="noPrint">
                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>

                                <Col sm="8" >

                                  <div className="text-sm-end" >
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={this.handleFineClicks}
                                    >
                                      <i className="mdi mdi-plus me-1" />{" "}
                                      {this.props.t("New Traffic fine")}
                                    </Button>

                                    <Link to="#"

                                    >

                                      {/*  <i className="mdi mdi-file-export-outline font-size-18" /> */}
                                      <td>
                                        <UncontrolledDropdown>
                                          <DropdownToggle href="#" className="card-drop" tag="a">
                                            <i className="mdi mdi-dots-vertical font-size-18" />
                                          </DropdownToggle>
                                          <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem href="#" onClick={() => fns.length == 0 ? this.setState({ no_data: true }) : exportPDF()}>
                                              <i className="mdi mdi-file-pdf-box font-size-16 text-danger me-1" />{" "}
                                              {this.props.t("Export PDF")}

                                            </DropdownItem>
                                            <DropdownItem href="#" onClick={() => fns.length == 0 ? this.setState({ no_data: true }) : ExportToExcel()} >

                                              <i className="mdi mdi-microsoft-excel font-size-16 text-success me-1" />{" "}
                                              {this.props.t("Export Excel")}
                                            </DropdownItem>
                                            <DropdownItem href="#" onClick={() => fns.length == 0 ? this.setState({ no_data: true }) : printPDF()}>
                                              <i className="mdi mdi-printer font-size-16 text-grey me-1" />{" "}
                                              {this.props.t("Print")}
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>

                                      </td>
                                    </Link>
                                  </div>
                                </Col>
                              </Row>
                              <div className="table-responsive">
                                <BootstrapTable

                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  responsive
                                  defaultSorted={defaultSorted}
                                  bordered={false}
                                  striped={false}
                                  noDataIndication={emptyDataMessage}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                />
                                 <Modal
                                  isOpen={this.state.modalDenied}
                                  role="alert"
                                  autoFocus={true}
                                  data-toggle="modalDenied"
                                  centered
                                  size="md"
                                >
                                  <ModalHeader toggle={this.toggleDenied} tag="h4">     </ModalHeader>

                                  <Row className="justify-content-center">
                                    <Col >

                                      <div className="p-2 mt-4 mb-0">
                                        <div className="text-center">
                                          <div className="avatar-md mx-auto">
                                            <div className="avatar-title rounded-circle bg-light">
                                              <i className="mdi mdi-shield-lock-outline h1 mt-4 text-primary mdi-48px"></i>
                                            </div>
                                          </div>
                                          <div className="p-2 mt-3 mb-0">
                                            <h4>{this.props.t("Permission Denied !")}</h4>
                                            <br />
                                            <p>


                                              {this.props.t("You do not have the required permissions to perform this action.")}<br /> {this.props.t("Please contact the administrator for assistance.")}

                                            </p>

                                          </div>
                                        </div>
                                      </div>

                                    </Col>
                                  </Row>
                                </Modal>
                                <Modal
                                  isOpen={this.state.modal}
                                  className={this.props.className}
                                >
                                  <ModalHeader toggle={this.toggle} tag="h4">
                                    {!!isEdit ? this.props.t("Edit Traffic fine ") : null}
                                    {!!this.state.isShow ? this.props.t("Traffic fine Infos") : null}
                                    {!isEdit && !this.state.isShow ? this.props.t("Add new traffic fine") : null}
                                  </ModalHeader>
                                  <ModalBody>
                                    {this.state.submitLoading ?
                                      <Col>
                                        <div>
                                          <div >
                                            <div className="spinner-chase">
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                            </div>
                                          </div>
                                        </div>
                                        <br />
                                        <h6 className='text-center'>{this.props.t("Uploading data ...")}</h6>
                                      </Col>

                                      :

                                      <AvForm
                                        onValidSubmit={
                                          this.handleValidFineSubmit
                                        }
                                        disabled={this.state.isShow ? true : false}
                                      >
                                        <Row>
                                          <Col md="6">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor="validationCustom01">
                                                {this.props.t("Label")}
                                              </Label>
                                              <AvField
                                                name="label"
                                                placeholder={this.props.t("Label")}
                                                type="text"
                                                className="form-control"
                                                value={this.state.fine.label || ""}

                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md="6">
                                            <FormGroup className="mb-4">
                                              <div className="mb-3 select2-container">
                                                <Label>{this.props.t("Vehicle")}</Label>
                                                {!isEdit && !this.state.isShow ?
                                                  <div>
                                                    <AvField
                                                      name="vehicle"
                                                      type="select"
                                                      placeholder={""}
                                                      // onChange={this.handleSelectVehicle}
                                                      //options={vcList}
                                                      value={this.state.fine.vehicle}
                                                      disabled={this.state.isShow || !!isEdit ? true : false}
                                                      errorMessage={this.props.t("Select a Vehicle")}
                                                      className="form-control"

                                                      validate={{
                                                        required: { value: true },
                                                      }}
                                                    >

                                                      {vcList.map((e, key) => {
                                                        if (key == 0) {
                                                          return <option key={key} value="" disabled>{this.props.t("vehicle")}</option>;
                                                        } else {
                                                          return <option key={key} value={e.id}>{e.label}</option>;
                                                        }
                                                      })}
                                                    </AvField>
                                                    <Link to="/vehicles" target="_blank" rel="noopener noreferrer">{this.props.t("Add new Vehicle")}</Link>
                                                  </div>
                                                  :
                                                  <AvField
                                                    name="vehicle"
                                                    type="text"
                                                    disabled={this.state.isShow || !!isEdit ? true : false}
                                                    value={this.state.fine.vehicle || ""}
                                                  />
                                                }
                                              </div>

                                            </FormGroup>
                                          </Col>
                                        </Row>

                                        <Row>

                                          <Col md="6">
                                            <FormGroup className="mb-4">
                                              <Label> {this.props.t("Date")}</Label>
                                              <AvField
                                                name="date"
                                                type="datetime-local"
                                                defaultValue={this.state.fine.date || ""}
                                                errorMessage={this.props.t("Select fine date")}
                                                //onChange={this.handleSDChange}
                                                disabled={this.state.isShow ? true : false}
                                                validate={{
                                                  required: { value: true },
                                                }}
                                              />
                                            </FormGroup>
                                          </Col>

                                          <Col md="6">
                                            <FormGroup className="mb-4">
                                              <Label>{this.props.t("Payment date")}</Label>
                                              <AvField
                                                name="payment_date"
                                                placeholder={this.props.t("Payment date")}
                                                type="date"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={Moment(this.state.fine.payment_date).format('YYYY-MM-DD') || ""}
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col md="6">
                                            <FormGroup className="mb-4">
                                              <div className="mb-3 select2-container">
                                                <Label>{this.props.t("Driver")}</Label>
                                                {!isEdit && !this.state.isShow ?
                                                  <div>
                                                    <AvField
                                                      name="driver"
                                                      type="select"
                                                      placeholder={""}
                                                      // onChange={this.handleSelectVehicle}
                                                      //options={vcList}
                                                      value={this.state.fine.driver}
                                                      disabled={this.state.isShow ? true : false}
                                                      //disabled={this.state.isShow || !!isEdit ? true : false}
                                                      errorMessage={this.props.t("Select the Driver")}
                                                      className="form-control"

                                                      validate={{
                                                        required: { value: true },
                                                      }}
                                                    >

                                                      {drList.map((e, key) => {
                                                        if (e.value === "staff") {
                                                          return <option key={key} value="" disabled>{this.props.t(e.label)}</option>;
                                                        } else if (e.value === "clients") {
                                                          return <option key={key} value="" disabled>{this.props.t(e.label)}</option>;
                                                        } else {
                                                          return <option key={key} value={e.label}>{e.label}</option>;
                                                        }
                                                      })}
                                                    </AvField>

                                                  </div>
                                                  :
                                                  <AvField
                                                    name="driver"
                                                    type="text"
                                                    disabled={this.state.isShow || !!isEdit ? true : false}
                                                    value={this.state.fine.driver || ""}
                                                  />
                                                }
                                              </div>

                                            </FormGroup>
                                          </Col>
                                          <Col md="6">
                                            <FormGroup className="mb-3">
                                              <FormGroup className="mb-3">
                                                <AvField
                                                  name="amount"
                                                  label={this.props.t("Amount")}
                                                  placeholder={this.props.t("Total amount")}
                                                  errorMessage={this.props.t("Enter Amount")}
                                                  type="number"
                                                  isDisabled={this.state.isShow ? true : false}
                                                  validate={{ required: { value: true } }}
                                                  value={this.state.fine.amount || ""}
                                                />
                                              </FormGroup>
                                            </FormGroup>
                                          </Col>
                                          {/* <Col md="3">
                                            <FormGroup className="mb-3">
                                              <Label>{this.props.t("Paid")}</Label>
                                              <div className="form-check mb-3">
                                                <input
                                                  name="paid"
                                                  className="form-check-input"
                                                  type="checkbox"
                                                  disabled={this.state.isShow ? true : false}
                                                  checked={this.state.fine.status || true}
                                                  onChange={this.checkChange}
                                                />
                                                <Label
                                                  className="form-check-label"
                                                  htmlFor="defaultCheck1"
                                                >
                                                  {this.props.t("All Paid")}
                                                </Label>
                                              </div>

                                            </FormGroup>
                                          </Col> */}
                                        </Row>


                                        <Row>
                                          <Col md="12">

                                            <FormGroup className="mb-3">
                                              <Label htmlFor="validationCustom03">
                                                {this.props.t("Details")}
                                              </Label>
                                              <AvField
                                                name="details"
                                                placeholder={this.props.t("Traffic fine Details")}

                                                type="textarea"
                                                rows="2"
                                                value={this.state.fine.details || ""}
                                              />
                                            </FormGroup>
                                          </Col>


                                        </Row>
                                        {/* <Row>

                                          <Col md="10" >
                                            <FormGroup className="mb-3">
                                              <AvField
                                                name="purchase_order"
                                                label={this.props.t("Purchase order")}
                                                placeholder={this.props.t("Purchase order id")}
                                                type="number"
                                                value={this.state.repair.purchase_order || ""}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md="2" >
                                            <FormGroup className="mb-3">

                                              <Link onClick={//console.log('')} >{this.props.t("Add new purchase order")}</Link>
                                            </FormGroup>
                                          </Col>

                                        </Row>

 */}
                                        {this.state.isShow ? null :
                                          <Row>
                                            <Col>
                                              <div className="text-end">

                                                <button
                                                  type="submit"
                                                  className="btn btn-success save-user"
                                                >
                                                  {this.props.t("Save")}
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                        }

                                      </AvForm>
                                    }
                                  </ModalBody>
                                </Modal>


                                {this.state.isDelete ? (
                                  <SweetAlert
                                    title={this.props.t("Are you sure?")}
                                    warning
                                    showCancel
                                    confirmButtonText={this.props.t("Yes, delete it!")}
                                    confirmBtnBsStyle="success"
                                    cancelBtnBsStyle="danger"
                                    onConfirm={() =>
                                      this.handleDeleteFine(vc)
                                    }
                                    onCancel={() =>
                                      this.setState({
                                        isDelete: false,
                                      })
                                    }
                                  >
                                    {this.props.t("You won't be able to revert this!")}
                                  </SweetAlert>
                                ) : null}
                                {this.state.no_data ? (
                                  <SweetAlert
                                    title={this.props.t("No data!")}
                                    warning
                                    onConfirm={() => this.setState({ no_data: false })}
                                  >
                                    {this.props.t("There is no data to export")}
                                  </SweetAlert>
                                ) : null}

                              </div>

                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
      </React.Fragment>
    )
  }
}

Fines.propTypes = {
  fines: PropTypes.array,
  vehicles: PropTypes.array,
  workshops: PropTypes.array,
  reservations: PropTypes.array,
  onGetRepair: PropTypes.func,
  onAddNewRepair: PropTypes.func,
  onDeleteRepair: PropTypes.func,
  onUpdateRepair: PropTypes.func,
  onGetVehicle: PropTypes.func,
  onGetReservation: PropTypes.func,
  onUpdateVehicle: PropTypes.func,
  onGetSupplier: PropTypes.func,
  className: PropTypes.any,
  t: PropTypes.any,
}

const mapStateToProps = state => ({

  vehicles: state.vehicle.vehicles,
  workshops: state.supplier.suppliers,
  reservations: state.reservation.reservations,
})

const mapDispatchToProps = dispatch => ({
  onGetRepair: () => dispatch(getRepair()),
  onGetVehicle: () => dispatch(getVehicle()),
  onGetSupplier: () => dispatch(getSupplier()),
  onGetReservation: () => dispatch(getReservation()),
  onAddNewRepair: repair => dispatch(addNewRepair(repair)),
  onUpdateRepair: repair => dispatch(updateRepair(repair)),
  onDeleteRepair: repair => dispatch(deleteRepair(repair)),
  onUpdateVehicle: vehicle => dispatch(updateVehicle(vehicle)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Fines)))
