import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Link, withRouter } from "react-router-dom"
import { Card, CardBody, Col, CardImg, CardFooter, Container, Row, Table, Modal, ModalBody } from "reactstrap"
import { isEmpty, map } from "lodash"
import generatePDF from "./GeneratePDF"; // Adjust the path as necessary
import * as moment from 'moment';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"
import "./print.css";
//Import Image
import logo from "../../assets/images/logo-dark.png"
import PropTypes from "prop-types"
import { getInvoiceDetail } from "../../store/invoices/actions"
import { connect } from "react-redux"
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';
import { propTypes } from "react-bootstrap-editable";
import writtenNumber, { i18n } from 'written-number';
import { withTranslation } from "react-i18next"

class Quote extends Component {
  constructor(props) {
    super(props)
    this.state = {
      useCompanyLogo: false,
      isDownloadingPDF: false,
      reservation: [],
      client_infos: [],
      indie_infos: [],
      car_make: "",
      car_model: "",
      agency: [],
      loading: false,
      modal: false
    }
  }
  componentDidMount() {
    const { reservation, agency } = this.state
    const url = window.location.href.toString()
    const r_id = url.substring(url.lastIndexOf("/") + 1)
    const pathParts = window.location.pathname.split('/');

    if (pathParts.length > 2 && pathParts[2]) {
      this.setState({ modal: true });
    } else {
      this.setState({ modal: false });
    }
    //console.log(r_id)
    if (reservation && !reservation.length) {
      this.getReservationData(r_id)
    }
    this.setState({ reservation })
    if (agency && !agency.length) {
      this.getAgency()
    }
    this.setState({ agency })
  }
  handleCheckboxChange = (event) => {
    this.setState({ useCompanyLogo: event.target.checked });
  };
  getAgency = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeUsers) {
      this.unsubscribeUsers();
    }

    this.unsubscribeUsers = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);
        //console.log(user)
        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userUsersCollectionRef = usersCollection.doc(userId)


            this.unsubscribeUsers = userUsersCollectionRef.onSnapshot(snapshot => {
              let kra = snapshot.data()
              this.setState({
                loading: false,
                agency: kra,
              })
            });



            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");

          }
        } catch (error) {
          //console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated

      }
    });
  }
  getReservationData = async (id) => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeReservations) {
      this.unsubscribeReservations();
    }

    this.unsubscribeReservations = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;

            const rDoc = await usersCollection.doc(userId).collection("quotes").doc(id).get();
            if (rDoc.exists) {
              let kra = null, cl = null, cli = null
              kra = rDoc.data()

              this.setState({
                loading: false,
                reservation: kra,
              })
              const cDoc = await usersCollection.doc(userId).collection("indies").doc(rDoc.data().client_id).get();
              if (cDoc.exists) {
                cli = cDoc.data()

                this.setState({
                  loading: false,
                  client_infos: cli,
                })
              }
              const vDoc = await usersCollection.doc(userId).collection("vehicles").doc(rDoc.data().v_id).get();
              if (vDoc.exists) {
                this.setState({
                  loading: false,
                  car_make: vDoc.data().make,
                  car_model: vDoc.data().model,
                })
              }
            }
          } else {
            // User document not found
            localStorage.removeItem("authUser");

          }
        } catch (error) {
          //console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated

      }
    });


  }





  /* getReservationData = async (id) => {
    firebase.auth().onAuthStateChanged(user => {
      let kra = null, cl = null, cli = null
      if (user) {
        user = firebase.auth().currentUser.uid
        const collection = firebase.firestore().collection("users")
        this.setState({ loading: true }, () => {
          collection.doc(user).collection("reservations").doc(id).get().then((doc) => {
            // //console.log(doc.data())
            kra = doc.data()

            this.setState({
              loading: false,
              reservation: kra,
            })
            
            collection.doc(user).collection("indies").doc(doc.data().client_id).get().then((cl) => {
              cli = cl.data()
              
              this.setState({
                loading: false,
                client_infos: cli,
              })
            })
            collection.doc(user).collection("vehicles").doc(doc.data().v_id).get().then((v) => {
              

              this.setState({
                loading: false,
                car_make: v.data().make,
                car_model: v.data().model,
              })
            })
          }
          );

        });

        localStorage.setItem("authUser", JSON.stringify(user))
      } else {
        ////console.log("not user")
        localStorage.removeItem("authUser")
      }
    })
  } */
  //Print the Quote
  printInvoice = () => {
    window.print()
  }


  generatePDF = async () => {
    try {
      // Show loading indicator
      this.setState({ isDownloadingPDF: true });

      const element = document.querySelector('.print-container');

      const opt = {
        margin: [10, 10, 0, 10], // Adjust as needed
        filename: `Quote_${this.state.reservation.code}.pdf`,
        image: {
          type: 'jpeg',
          quality: 0.98
        },
        html2canvas: {
          scale: 2,
          useCORS: true,
          logging: false
        },
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'portrait'
        }
      };

      // Wrap html2pdf in a promise to handle async operation
      await new Promise((resolve) => {
        html2pdf()
          .set(opt)
          .from(element)
          .save()
          .then(() => {
            resolve();
          });
      });
    } catch (error) {
      // Handle any errors
      console.error('PDF generation error:', error);
      // Optionally show an error message to the user
      this.setState({
        pdfDownloadError: 'Failed to download PDF. Please try again.'
      });
    } finally {
      // Always hide loading indicator
      this.setState({ isDownloadingPDF: false });
    }
  }

  render() {

    const { useCompanyLogo, isDownloadingPDF, reservation, client_infos, car_make, car_model, agency, loading, modal } = this.state
    //console.log(reservation)
    const subtotal = parseInt(reservation.amount) + parseInt(reservation.frais_livraison) + parseInt(reservation.frais_reprise) + parseInt(reservation.frais_divers)
    const tva = (parseInt(reservation.amount) + parseInt(reservation.frais_livraison) + parseInt(reservation.frais_reprise) + parseInt(reservation.frais_divers)) * 0.2
    const total = subtotal + tva
    const lang = this.props.i18n.language
    { this.props.t("Total Day") }
    return (
      <React.Fragment>
        <Modal isOpen={isDownloadingPDF}>
          <ModalBody>
            <Col>
              <div>
                <div >
                  <div className="spinner-chase">
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                  </div>
                </div>
              </div>
              <br />
              <h6 className='text-center'>{this.props.t("Downloading Quote...")}</h6>
            </Col>

          </ModalBody>
        </Modal>
        <div className="page-content">

          <MetaTags>
            <title>{this.props.t("Quote | Medios - Car Rental Management System")}</title>
          </MetaTags>

          <Container fluid id="down">
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={this.props.t("Reservations")} breadcrumbItem={this.props.t("Quote")} />

            <Row className="text-center"  >
              {loading ? <Col xs="12">
                <div className="text-center my-3">
                  <Link to="#" className="text-success">
                    <i className="bx bx-hourglass bx-spin me-2" />
                    {this.props.t("Loading . . .")}
                  </Link>
                </div>
              </Col> :
                <Col lg="12" id="down">
                  <div className="form-check mb-4 d-print-none">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck2"
                      checked={useCompanyLogo} // Bind the state
                      onChange={this.handleCheckboxChange} // Handle change
                    />
                    <label
                      className="form-check-label"
                      htmlFor="defaultCheck2"
                    >
                      {this.props.t("Use Company logo")}
                    </label>
                  </div>
                  <Card >

                    <CardBody>

                      <div className="print-container">
                        <div className="print-header">



                          <div className="invoice-title">
                            <Row className="justify-items-center mb-2">
                              {/* <Col className="col-sm-7 text-center text-sm-start mb-2  mb-sm-0"> <img id="logo" src={logo} width="90" height="30" title="Medios" alt="Medios"></img> </Col> */}
                              {useCompanyLogo ? <Col className="text-start mb-2" >
                                <img src={agency.documents_logo} alt="logo-dark" className="logo-dark-element" height="40" />

                              </Col> : null}
                              <Col className=" text-start " >
                                <h2 className="mb-0 ">{this.props.t("Quote")}</h2>
                                <p className="mb-0 ">{this.props.t("Quote Number")} : <span className="fw-bold">{reservation.code}</span></p>
                              </Col>

                            </Row>

                          </div>

                        </div>

                        <div className="inv-content">
                          <Container fluid>


                            <h5 className="text-start fw-bold mt-0 mb-2">{this.props.t("Billed to")} :</h5>

                            <div >
                              <table className="table rounded-table table-sm">
                                <tbody>
                                  <tr>
                                    <td className="col-3 text-start">
                                      <span className="fw-bold">{this.props.t("Name")} :</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                      {client_infos.first_name + " " + client_infos.last_name}
                                    </td>
                                    {/* <td className="col-3 text-start">
                                        <span className="fw-bold">{this.props.t("Date of Birth")}:</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                        {client_infos.date_birth}
                                      </td>
 */}
                                    {/* <td className="col-3 text-start">
          <span className="fw-bold">{this.props.t("Valid Till")}:</span><br />
          {client_infos.id_expiration_date}
        </td> */}
                                    <td className="col-3 text-start">
                                      <span className="fw-bold">{this.props.t("CIN/Passport Number")}:</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                      {client_infos.id_number}
                                    </td>
                                    <td className="col-3 text-start">
                                      <span className="fw-bold">{this.props.t("Phone")}:</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                      {client_infos.mobile}
                                    </td>
                                  </tr>
                                  <tr>
                                    {/* <td className="col-4 text-start">
          <span className="fw-bold">{this.props.t("Driving License Number")}:</span><br />
          {client_infos.dl_number}
        </td> */}




                                  </tr>
                                  <tr>
                                    <td className="col-12 text-start" colSpan="3">
                                      <span className="fw-bold">{this.props.t("Address")}:</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                      {client_infos.address + ", " + client_infos.city + ", " + client_infos.country}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <h5 className="text-start fw-bold ">{this.props.t("Vehicle & Rental Details")} : </h5>
                            <Row>
                              <Col sm="7" className="text-sm-start">


                                <table className="table  table-sm">
                                  <tbody>
                                    <tr>
                                      <td className="col-4 text-start fw-bold">{this.props.t("Make & Model")}:</td>
                                      <td className="col-8">{car_make + " " + car_model}</td>
                                    </tr>
                                    <tr>
                                      <td className="text-start fw-bold">{this.props.t("Registration Number")}:</td>
                                      <td>{reservation.plate_number}</td>
                                    </tr>
                                    <tr>
                                      <td className="text-start fw-bold">{this.props.t("Rental Start Date")}:</td>
                                      <td>{moment(reservation.pickup_date).format('DD-MM-YYYY')}</td>
                                    </tr>
                                    <tr>
                                      <td className="text-start fw-bold">{this.props.t("Rental End Date")}:</td>
                                      <td>{moment(reservation.return_date).format('DD-MM-YYYY')}</td>
                                    </tr>

                                  </tbody>
                                </table>
                              </Col>
                              <Col sm="5" className="text-sm-end">
                                <table className="table  table-sm">
                                  <tbody>
                                    <tr>
                                      <td className="col-6 text-start fw-bold">{this.props.t("Quote number")}:</td>
                                      <td className="col-6">{reservation.code}</td>
                                    </tr>
                                    <tr>
                                      <td className="text-start fw-bold">{this.props.t("Quote Date")}:</td>
                                      <td>{moment(new Date()).format('DD-MM-YYYY')}</td>
                                    </tr>

                                  </tbody>
                                </table>

                              </Col>
                            </Row>





                            <h5 className="text-start fw-bold ">{this.props.t("Charges & Fees")} : </h5>
                            <div className="">
                              <table className="table table-border  table-sm">
                                <thead className="card-header">
                                  <tr>
                                    <td className=" text-start fw-bold">{this.props.t("Description")}</td>
                                    <td className="text-end fw-bold">{this.props.t("Unit Price (MAD)")}</td>
                                    <td className="text-end fw-bold">{this.props.t("Unit")}</td>
                                    <td className=" text-end fw-bold">{this.props.t("Quantity")}</td>
                                    <td className=" text-end fw-bold">{this.props.t("Total (MAD)")}</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className=" text-start">{this.props.t("Net de location")} </td>
                                    <td className=" text-end">{parseInt(reservation.amount / reservation.days)}</td>
                                    <td className=" text-end">{this.props.t("Days")}</td>
                                    <td className=" text-end">{reservation.days}</td>
                                    <td className=" text-end">{reservation.amount}</td>
                                  </tr>

                                  <tr>
                                    <td className="text-start">{this.props.t("Delivery fee")}<br />
                                      {/* <span className="text-1">SUMMERFUN - 10% One Time Discount</span>*/}</td>
                                    <td className="text-end">{reservation.frais_livraison}</td>
                                    <td className="text-end">{this.props.t("-")}</td>
                                    <td className="text-end">{1}</td>
                                    <td className="text-end">{reservation.frais_livraison}</td>
                                  </tr>
                                  <tr>
                                    <td className=" text-start">{this.props.t("Return fee")}<br />
                                      {/* <span className="text-1">SUMMERFUN - 10% One Time Discount</span>*/}</td>
                                    <td className="text-end">{reservation.frais_reprise}</td>
                                    <td className="text-end">{this.props.t("-")}</td>
                                    <td className="text-end">{1}</td>
                                    <td className="text-end">{reservation.frais_reprise}</td>
                                  </tr>
                                  <tr>
                                    <td className=" text-start">{this.props.t("Divers")}</td>
                                    <td className="text-end">{reservation.frais_divers}</td>
                                    <td className="text-end">{this.props.t("-")}</td>
                                    <td className="text-end">{1}</td>
                                    <td className="text-end">{reservation.frais_divers}</td>
                                  </tr>
                                </tbody>
                                <tfoot className="card-footer">
                                  <tr>
                                    <td colSpan="4" className="text-end border-bottom-0"><strong>{this.props.t("Sub Total")}:</strong></td>
                                    <td className="text-end border-bottom-0">{subtotal}</td>
                                  </tr>
                                  <tr>
                                    <td colSpan="4" className="text-end border-bottom-0"><strong>{this.props.t("TVA 20%")}:</strong></td>
                                    <td className="text-end border-bottom-0">{tva}</td>
                                  </tr>

                                  <tr>
                                    <td colSpan="1" className="  text-start border-bottom-0 "><strong className="text-uppercase"> {writtenNumber(total, { lang: lang })} </strong>{this.props.t("MAD")}</td>
                                    <td colSpan="3" className="text-end border-bottom-0 "><strong>{this.props.t("Total")}:</strong></td>
                                    <td className="text-end border-bottom-0"><strong>{total}</strong></td>
                                  </tr>
                                </tfoot>

                              </table>


                            </div>
                            {/* <Row className="mb-0">
                                <Col md="12">
                                  <Row className="mb-3">
                                    <Col xs="12">
                                      <h5 className="text-start fw-bold">{this.props.t("Payment method")}:</h5>
                                    </Col>
                                    <Col xs="12">
                                      <Row className="flex-nowrap overflow-auto">
                                        <Col xs="auto">
                                          <div className="d-flex align-items-center">
                                            <div style={{ width: '15px', height: '15px', border: '1px solid #000', marginRight: '10px', borderRadius: '3px' }}></div>
                                            <span>{this.props.t("Cash")}</span>
                                          </div>
                                        </Col>
                                        <Col xs="auto">
                                          <div className="d-flex align-items-center">
                                            <div style={{ width: '15px', height: '15px', border: '1px solid #000', marginRight: '10px', borderRadius: '3px' }}></div>
                                            <span>{this.props.t("Bank Transfer")}</span>
                                          </div>
                                        </Col>
                                        <Col xs="auto">
                                          <div className="d-flex align-items-center">
                                            <div style={{ width: '15px', height: '15px', border: '1px solid #000', marginRight: '10px', borderRadius: '3px' }}></div>
                                            <span>{this.props.t("Credit/Debit Card")}</span>
                                          </div>
                                        </Col>
                                        <Col xs="auto">
                                          <div className="d-flex align-items-center">
                                            <div style={{ width: '15px', height: '15px', border: '1px solid #000', marginRight: '10px', borderRadius: '3px' }}></div>
                                            <span>{this.props.t("Other")}</span>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs="12">
                                      <h5 className="text-start fw-bold">{this.props.t("Payment status")}:</h5>
                                    </Col>
                                    <Col xs="12">
                                      <Row className="flex-nowrap overflow-auto">
                                        <Col xs="auto">
                                          <div className="d-flex align-items-center">
                                            <div style={{ width: '15px', height: '15px', border: '1px solid #000', marginRight: '10px', borderRadius: '3px' }}></div>
                                            <span>{this.props.t("Paid")}</span>
                                          </div>
                                        </Col>
                                        <Col xs="auto">
                                          <div className="d-flex align-items-center">
                                            <div style={{ width: '15px', height: '15px', border: '1px solid #000', marginRight: '10px', borderRadius: '3px' }}></div>
                                            <span>{this.props.t("Not paid")}</span>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row> */}
                            {/* <Col className="d-flex align-items-center mb-2">
                                <div
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    border: '2px solid #000', // Border only
                                    marginRight: '10px',
                                  }}
                                ></div>
                                <span>kra kolo</span>
                              </Col> */}
                          </Container>

                        </div>
                        <br /><br /><br /><br /><br /><br />
                        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />

                        <div className="print-footer" >
                          <footer>
                            <Row className="justify-items-center">
                              {/* <Col className="col-sm-7 text-center text-sm-start mb-2  mb-sm-0"> <img id="logo" src={logo} width="90" height="30" title="Medios" alt="Medios"></img> </Col> */}
                              <Col className=" text-center ">
                                <p><strong>{agency.company_name}</strong><br />
                                  {agency.address} - {this.props.t("Email")} : {agency.email} <br />
                                  {this.props.t("Tél")}: {agency.phone} - {this.props.t("RC")} : {agency.rc} - {this.props.t("T.P")}: {agency.tp} - {this.props.t("ICE")}: {agency.ice} - {this.props.t("IF")}: {agency.id_fiscal}.
                                </p>
                              </Col>
                            </Row>

                          </footer>


                        </div>
                      </div>
                    </CardBody>


                  </Card>
                  <div className="d-print-none mb-4">
                    <div className="float-center">
                      <Link
                        to="#"
                        onClick={this.printInvoice}
                        className="btn btn-success me-1"
                      >
                        {this.props.t("Print")}{" "}<i className="fa fa-print" />
                      </Link>{" "}
                      <Link
                        to="#"
                        onClick={this.generatePDF}
                        className="btn btn-primary me-1"
                      >
                        {this.props.t("Save PDF")}{" "}<i className="fa fa-file-pdf" />
                      </Link>{" "}
                    </div>
                  </div>
                </Col>

              }


            </Row>

          </Container>

        </div>
      </React.Fragment>
    )
  }
}

Quote.propTypes = {
  invoiceDetail: PropTypes.object,
  reservation: PropTypes.object,
  match: PropTypes.object,
  onGetInvoiceDetail: PropTypes.func,
  t: PropTypes.any,
  i18n: PropTypes.any
}

const mapStateToProps = ({ invoices }) => ({
  invoiceDetail: invoices.invoiceDetail,
})

const mapDispatchToProps = dispatch => ({
  onGetInvoiceDetail: id => dispatch(getInvoiceDetail(id)),
})


export default connect(
  mapStateToProps, mapDispatchToProps
)(withRouter(withTranslation()(Quote)))