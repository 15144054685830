import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { countBy, isEmpty, size } from "lodash"
import { Link } from "react-router-dom"
import Select from "react-select"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import toastr from "toastr";
import toast, { Toaster } from 'react-hot-toast';
import * as moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  FormGroup,
  Label
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

// Add the Firebase products that you want to use
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"

import TodayPickups from "../../Dashboard/Today_pickups"
import TodayReturns from "../../Dashboard/Today_returns"

import FullCalendar, { constrainPoint } from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
//css
import "@fullcalendar/bootstrap/main.css"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  getEvents,
  addNewEvent,
  updateEvent,
  deleteEvent,
  getCategories,
  getReservation,
  addNewReservation,
  updateReservation,
  deleteReservation,
  getVehicle,
  getIndie,
  getTariff,
  updateVehicle
} from "store/actions"
import DeleteModal from "./DeleteModal"
import { reservations } from "common/data";
import Moment from 'moment';

class Calender extends Component {
  constructor(props) {
    super(props)
    this.handleDateClick = this.handleDateClick.bind(this)
    this.calendarComponentRef = React.createRef()

    this.state = {
      p_loading: false,
      modalDenied: false,
      reservationPermissions: null,
      user_id: "",
      loading: true,
      submitLoading: false,
      calendarWeekends: true,
      totalAmount: "",
      p: false,
      modal: false,
      rdchanged: false,
      pdchanged: false,
      modalcategory: false,
      isDragBind: false,
      deleteModal: false,

      event: {},
      reservations: [],
      todaysP: [],
      todaysR: [],
      reservation: [],
      tariffs: [],
      vehicles: [],
      indies: [],
      repairs: [],
      isDelete: false,
      mile: 0,
      vc: "",
      selectedTariff: null,
      trfchanged: false,
      selectedType: null,
      selectedVehicle: null,
      selectedClient: null,
      PD: new Date(),
      RD: new Date().setDate(new Date().getDate() + 1),
    }
    this.toggleDenied = this.toggleDenied.bind(this)
    this.toggle = this.toggle.bind(this)
    this.togglecategory = this.togglecategory.bind(this)
    this.handleValidEventSubmit = this.handleValidEventSubmit.bind(this)
    this.handleSelectType = this.handleSelectType.bind(this)
    this.handleSelectVehicle = this.handleSelectVehicle.bind(this)
    this.handleSelectClient = this.handleSelectClient.bind(this)
    this.handleSelectTariff = this.handleSelectTariff.bind(this)
    this.handlePDChange = this.handlePDChange.bind(this)
    this.handleRDChange = this.handleRDChange.bind(this)


    this.getAmount = this.getAmount.bind(this)

  }
  toggleDenied() {
    this.setState(prevState => ({
      modalDenied: !prevState.modalDenied,
    }))
  }
  handleDeleteClick = (reservation) => {

    const { reservationPermissions, p_loaded } = this.state;

    if (!p_loaded) {
      return; // Exit early if permissions are not yet loaded
    }

    if (reservationPermissions === null || reservationPermissions.delete) {
      this.setState({ isDelete: true, vc: reservation });
    } else {
      this.setState({ modalDenied: true }, () => {
        console.log("Permission denied");
      });
    }

  };
  checkChange = p => {
    this.setState({ p: p.target.checked })

  }
  handlePDChange = PD => {

    this.setState({ PD: PD.target.value })
    this.setState({ pdchanged: true })
    /* //console.log("pdchanged")
    //console.log("pd : " + this.state.PD)
    //console.log("rd : " + this.state.RD) */
    //console.log("rdchanged " + this.state.rdchanged)

    /* if(!this.state.rdchanged){
      this.setState({
        reservations: {
          
          return_date:  new Date(PD.target.value).setDate(new Date(PD.target.value).getDate() + 1),
          end:new Date(PD.target.value).setDate(new Date(PD.target.value).getDate() + 1),
          
        },
        RD:new Date(PD.target.value).setDate(new Date(PD.target.value).getDate() + 1),
        
      })
    } */

  }
  handleRDChange = RD => {

    this.setState({ RD: RD.target.value })
    this.setState({ rdchanged: true })
    //console.log("rdchanged")
    //console.log("pd : " + this.state.PD)
    //console.log("rd : " + this.state.RD)
  }


  handleSelectType = selectedType => {

    this.setState({ selectedType: selectedType.value })
    this.setState({ changed: true })
  }
  handleSelectTariff = (selectedTariff, value) => {

    this.setState({ selectedTariff: value })
    this.setState({ trfchanged: true })

  }
  getAmount() {
    let oss = this.calculateDays(this.state.PD, this.state.RD)
    //console.log(this.state.PD)
    //console.log(this.state.RD)
    //console.log(oss)
    //console.log(this.state.selectedTariff)
    let kra2 = ""
    //console.log(this.state.trfchanged)
    if (this.state.selectedTariff !== null) {
      if (this.state.trfchanged) {
        kra2 = this.state.selectedTariff
        // let trfid = kra2.substring(kra2.indexOf("(") + 1, kra2.indexOf(")"))
        this.calculateAmount(kra2, oss)
      }
      else {
        kra2 = this.state.selectedTariff.value
        this.calculateAmount(kra2, oss)
      }
    }
  }
  calculateAmount(tariff, days) {


    var total = 0;
    const { tariffs } = this.state
    if (days <= 0) {
      total = 0
    } else {
      tariffs.map(function (item, i) {
        if (item.id == tariff) {
          if (days < 30) {
            total = days * item.daily_rate;
          } else {
            var month = Math.floor(days / 30) * item.monthly_rate
            var day = (days % 30) * item.daily_rate
            total = month + day
          }
        }

      })
    }
    //console.log(total)
    this.setState({ totalAmount: total })
  }
  calculateDays(date1, date2) {

    var pd = new Date(date2);
    var rd = new Date(date1);
    var pppd = moment(pd, 'DD-MM-YYYY');
    var rrrd = moment(rd, 'DD-MM-YYYY');

    var DD = pppd.diff(rrrd, 'days');
    //console.log(DD)
    return DD
  }
  handleSelectVehicle = (selectedVehicle, value) => {
    const { vehicles } = this.state
    /* let kra = selectedVehicle.target.value
    let plt = kra.substring(kra.indexOf("(") + 1, kra.indexOf(")")) */
    const p = vehicles.find(v => v.id === value);
    var plt = ""
    if (p !== undefined) {
      plt = p.plate_number
    }
    let m = 0
    //console.log(plt)
    vehicles.map((e, key) => {

      if (e.plate_number === plt) {
        m = e.mileage
      }
    })
    this.setState({
      mile: m
    })
  }
  handleSelectClient = selectedClient => {

    this.setState({ selectedClient: selectedClient })
    this.setState({ clchanged: true })
  }
  componentWillUnmount() {

    this.setState({
      loading: null,
      submitLoading: null,
      calendarWeekends: null,
      totalAmount: null,
      p: null,
      modal: null,
      rdchanged: null,
      pdchanged: null,
      modalcategory: null,
      isDragBind: null,
      deleteModal: null,
      event: null,
      reservations: null,
      todaysP: null,
      todaysR: null,
      reservation: null,
      tariffs: null,
      vehicles: null,
      indies: null,
      repairs: null,
      isDelete: null,
      mile: null,
      vc: null,
      selectedTariff: null,
      trfchanged: null,
      selectedType: null,
      selectedVehicle: null,
      selectedClient: null,
      PD: null,
      RD: null,
    });
  }

  componentDidMount = () => {
    const { onGetCategories, onGetEvents } = this.props
    const { reservations, onGetReservation } = this.state

    if (reservations && !reservations.length) {

      // onGetReservation()
      this.getReservation()
    }
    //this.setState({ reservations })
    const { vehicles, onGetVehicle } = this.state
    if (vehicles && !vehicles.length) {
      //onGetVehicle()
      this.getVehicle()
    }
    this.setState({ vehicles })

    const { indies, onGetIndie } = this.state
    if (indies && !indies.length) {
      //onGetIndie()
      this.getIndie()
    }
    this.setState({ indies })

    const { tariffs, onGetTariff } = this.state
    if (tariffs && !tariffs.length) {
      //onGetTariff()
      this.getTariff()
    }
    this.setState({ tariffs })

    const { repairs } = this.state
    if (repairs && !repairs.length) {
      //onGetTariff()
      this.getRepair()
    }
    this.setState({ tariffs })
    onGetCategories()
    onGetEvents()
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    })
    this.getReservationPermissions()
  }
  async getReservationPermissions() {
    try {
      this.setState({ p_loaded: false })
      firebase.auth().onAuthStateChanged(async user => {
        if (user) {
          const userId = user.uid;
          // Reference to the user document
          const userDocRef = firebase.firestore().collection("users").doc(userId);

          // Fetch the user document
          const userSnapshot = await userDocRef.get();

          if (!userSnapshot.exists) {
            this.setState({ p_loaded: true })
            console.log("User document does not exist.");
            return null; // Return null if user document doesn't exist
          }

          const userData = userSnapshot.data();

          // Check if the user is an admin
          if (userData.isAdmin) {
            this.setState({ p_loaded: true })
            //console.log("User is an admin; permissions are not restricted.");
            return null; // Return null or handle differently for admins
          }

          // Reference to the permissions document for 'clients'
          const permissionsDocRef = userDocRef.collection("permissions").doc("reservations");

          // Fetch the document
          const permissionsSnapshot = await permissionsDocRef.get();

          if (permissionsSnapshot.exists) {
            const reservationPermissions = permissionsSnapshot.data();
            /// console.log("Client Permissions:", clientPermissions);
            this.setState({ reservationPermissions });
            this.setState({ p_loaded: true })
            return reservationPermissions; // Return the permissions as an object
          } else {
            this.setState({ p_loaded: true })
            console.log("No permissions found for 'reservations'.");
            return null; // Return null if no permissions exist
          }
        } else {
          this.setState({ p_loaded: true })
          console.log("Not authenthicated")
        }
      })

    } catch (error) {
      this.setState({ p_loaded: true })
      console.error("Error fetching reservations permissions:", error);
      throw error;
    }
  }

  // eslint-disable-next-line no-unused-vars
  /* componentDidUpdate(prevProps, prevState, snapshot) {
    const { event, modal } = this.state
    if (prevState.modal !== modal && !modal && !isEmpty(event)) {
      setTimeout(() => {
        this.setState({ event: {}, isEdit: false })
      }, 500)
    }
    const { reservations } = this.state
    const { vehicles } = this.state
    const { indies } = this.state
    const { tariffs } = this.state



    if (!isEmpty(reservations) && size(prevProps.reservations) !== size(reservations)) {
      //this.setState({ reservations: {}, isEdit: false })
    }
    if (!isEmpty(vehicles) && size(prevProps.vehicles) !== size(vehicles)) {
      //this.setState({ vehicles: {}, isEdit: false })
    }
    if (!isEmpty(indies) && size(prevProps.indies) !== size(indies)) {
      //this.setState({ indies: {}, isEdit: false })
    }

    if (!isEmpty(tariffs) && size(prevProps.tariffs) !== size(tariffs)) {
      //this.setState({ tariffs: {}, isEdit: false })
    }
  } */
  getRepair = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeRepairs) {
      this.unsubscribeRepairs();
    }

    this.unsubscribeRepairs = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userRepairsCollectionRef = usersCollection.doc(userId).collection("repairs");

            this.unsubscribeRepairs = userRepairsCollectionRef.onSnapshot(snapshot => {
              let kra = []
              snapshot.docs.forEach(e => {
                kra.push(e.data())
              });
              this.setState({
                loading: false,
                repairs: kra,
              })
            })

            localStorage.setItem("authUser", JSON.stringify(user))
          } else {
            ////console.log("not user")
            localStorage.removeItem("authUser")
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      }

    })
  }

  getReservation = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeReservations) {
      this.unsubscribeReservations();
    }

    this.unsubscribeReservations = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userReservationsCollectionRef = usersCollection.doc(userId).collection("reservations");
            const CurrentDate = moment().unix();
            const lyoum = moment.unix(CurrentDate).format("DD/MM/YYYY");
            this.setState({
              user_id: userId,
              account_user: userDoc.data()
            })

            this.unsubscribeReservations = userReservationsCollectionRef.onSnapshot(snapshot => {
              let reservations = [];
              let kayninP = [];
              let kayninR = [];
              let kkk = "";
              let kkkk = "";

              snapshot.docs.forEach(e => {
                kkk = moment.unix(e.data().pd / 1000).format("DD/MM/YYYY");
                kkkk = moment.unix(e.data().rd / 1000).format("DD/MM/YYYY");
                if (kkk === lyoum) {
                  kayninP.push(e.data());
                }
                if (kkkk === lyoum) {
                  kayninR.push(e.data());
                }
                reservations.push(e.data());
              });
              this.setState({
                loading: false,
                reservations: reservations,
                todaysP: kayninP,
                todaysR: kayninR
              });

            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            this.setState({
              loading: false,
              reservations: [],
              todaysP: [],
              todaysR: []
            });
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          loading: false,
          reservations: [],
          todaysP: [],
          todaysR: []
        });
      }
    });
  }


  getVehicle = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeVehicles) {
      this.unsubscribeVehicles();
    }

    this.unsubscribeVehicles = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userVehiclesCollectionRef = usersCollection.doc(userId).collection("vehicles");
            this.setState({
              user_id: userId
            })
            this.unsubscribeVehicles = userVehiclesCollectionRef.onSnapshot(snapshot => {
              let vehicles = [];
              snapshot.docs.forEach(doc => {
                vehicles.push(doc.data());
              });

              this.setState({
                loading: false,
                vehicles: vehicles
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            this.setState({
              loading: false,
              vehicles: []
            });
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          loading: false,
          vehicles: []
        });
      }
    });

  }


  getIndie = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeIndies) {
      this.unsubscribeIndies();
    }

    this.unsubscribeIndies = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userIndiesCollectionRef = usersCollection.doc(userId).collection("indies");
            this.setState({
              user_id: userId
            })
            this.unsubscribeIndies = userIndiesCollectionRef.onSnapshot(snapshot => {
              let n = [];
              snapshot.docs.forEach(doc => {
                n.push(doc.data());
              });

              this.setState({
                loading: false,
                indies: n
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");

            this.setState({
              loading: false,
              indies: []
            });

          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          loading: false,
          indies: []
        });
      }
    });

  }


  getTariff = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeTariffs) {
      this.unsubscribeTariffs();
    }

    this.unsubscribeTariffs = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userTariffsCollectionRef = usersCollection.doc(userId).collection("tariffs");
            this.setState({
              user_id: userId
            })
            this.unsubscribeTariffs = userTariffsCollectionRef.onSnapshot(snapshot => {
              let t = [];
              snapshot.docs.forEach(doc => {
                t.push(doc.data());
              });

              this.setState({
                loading: false,
                tariffs: t
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            this.setState({
              loading: false,
              tariffs: []
            });
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          loading: false,
          tariffs: []
        });
      }
    });


  }
  /**
   * Handling the modal state
   */
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }


  togglecategory() {
    this.setState(prevState => ({
      modalcategory: !prevState.modalcategory,
    }))
  }

  /**
   * Handling date click on calendar
   */
  handleDateClick = arg => {
    const { reservationPermissions, p_loaded } = this.state;

    if (!p_loaded) {
      return; // Exit early if permissions are not yet loaded
    }

    if (reservationPermissions === null || reservationPermissions.add) {
      this.setState({ reservation: '', isEdit: false, isShow: false, selectedTariff: null, selectedVehicle: null, selectedClient: null, totalAmount: null, p: false, PD: new Date(), RD: new Date().setDate(new Date().getDate() + 1) })
      ////console.log("handle date click "+arg['date'])
      if (arg['date'] != null) {
        const date = arg['date'];
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        const currectDate = new Date();
        const currentHour = currectDate.getHours();
        const currentMin = currectDate.getMinutes();
        const currentSec = currectDate.getSeconds();
        const modifiedDate = new Date(year, month, day, currentHour, currentMin, currentSec);
        const md = moment(modifiedDate).format('YYYY-MM-DDTHH:mm')
        //console.log("handle date click " + md)
        const k = new Date(md).setDate(new Date(md).getDate() + 1)
        //console.log("next date", moment(k).format('YYYY-MM-DDTHH:mm'))
        const modifiedData = { ...arg, date: md };
        this.setState({ selectedDay: modifiedData })
        this.setState({
          reservation: {

            pickup_date: md,
            return_date: moment(k).format('YYYY-MM-DDTHH:mm'),
            start: md,
            end: moment(k).format('YYYY-MM-DDTHH:mm'),
            /* reciept:reservation.reciept,
            invoice:reservation.invoice */
          },
          PD: md,
          RD: new Date(md).setDate(new Date(md).getDate() + 1),
          isShow: false,
        })

      } else {
        //console.log("elseeeeee")
        const date = new Date()
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        const currectDate = new Date();
        const currentHour = currectDate.getHours();
        const currentMin = currectDate.getMinutes();
        const currentSec = currectDate.getSeconds();
        const modifiedDate = new Date(year, month, day, currentHour, currentMin, currentSec);
        const md = moment(modifiedDate).format('YYYY-MM-DDTHH:mm')
        //console.log("handle date click " + md)
        const k = new Date(md).setDate(new Date(md).getDate() + 1)
        //console.log("next date", moment(k).format('YYYY-MM-DDTHH:mm'))
        /* const modifiedData = { ...arg, date: md };
        this.setState({ selectedDay: modifiedData }) */
        this.setState({
          reservation: {

            pickup_date: md,
            return_date: moment(k).format('YYYY-MM-DDTHH:mm'),
            start: md,
            end: moment(k).format('YYYY-MM-DDTHH:mm'),
            /* reciept:reservation.reciept,
            invoice:reservation.invoice */
          },
          PD: md,
          RD: new Date(md).setDate(new Date(md).getDate() + 1),
          isShow: false,
        })
      }

      this.toggle();
    } else {
      this.setState({ modalDenied: true }, () => {
        console.log("Permission denied");
      });
    }



  }
  handleDeleteReservation = () => {



    const { vehicles, onUpdateVehicle, reservation } = this.state
    ////console.log(reservation)
    this.deleteReservation(reservation)
    this.toggleDelete()
    this.toggle();
  }

  /**
   * Handling click on event on calendar
   */
  handleEventClick = arg => {

   
      const reservation = arg.event.extendedProps
      if (reservation.label === undefined) {
        ////console.log(arg.event.id)
        this.setState({ rdchanged: false, pdchanged: false, totalAmount: null, p: reservation.paid, PD: reservation.pickup_date, RD: reservation.return_date })
        // this.setState({ totalAmount: null, PD: reservation.pickup_date, RD: reservation.return_date })
        this.setState({ selectedVehicle: { label: reservation.vehicle, value: reservation.plate_number } })
        this.setState({ selectedClient: { label: reservation.client, value: reservation.client_id } })
        this.setState({ selectedTariff: { label: reservation.vehicle_type, value: reservation.tariff_id } })
        this.setState({
          reservation: {
            id: arg.event.id,
            code: reservation.code,
            plate_number: reservation.plate_number,
            vehicle: reservation.vehicle,
            client: reservation.client,
            client_id: reservation.client_id,
            pickup_date: reservation.pickup_date,
            pd: reservation.pd,
            pickup_location: reservation.pickup_location,
            return_date: reservation.return_date,
            rd: reservation.rd,
            return_location: reservation.return_location,
            vehicle_type: reservation.vehicle_type,
            tariff_id: reservation.tariff_id,
            amount: reservation.amount,
            total_amount: reservation.total_amount,/* 
            rest_amount: reservation.rest_amount,
            paid_amount: reservation.paid_amount,
            paid: reservation.paid, */
            notes: reservation.notes,
            days: reservation.days,
            status: reservation.status,
            start: reservation.pickup_date,
            end: reservation.return_date,
            frais_livraison: reservation.frais_livraison,
            frais_reprise: reservation.frais_reprise,
            frais_divers: reservation.frais_divers,
            caution: reservation.caution,
            mileage_pickup: reservation.mileage_pickup,
            mileage_return: reservation.mileage_return,
            fuel_level_pickup: reservation.fuel_level_pickup,
            fuel_level_return: reservation.fuel_level_return,
            title: reservation.vehicle + " (" + reservation.client + ")"

            /* reciept:reservation.reciept,
            invoice:reservation.invoice */
          },
          isEdit: true,
          isShow: false,
        })

        this.toggle()
      }
    

    


  }

  /**
   * Handling submit event on event form
   */
  handleValidEventSubmit = (e, values) => {

    const { reservationPermissions, p_loaded } = this.state;

    if (!p_loaded) {
      return; // Exit early if permissions are not yet loaded
    }
   const { onAddNewReservation, onUpdateReservation } = this.props
    const { isEdit, reservations, selectedReservation, vehicles } = this.state

    const cds = []
    this.state.reservations.forEach(e => {
      var cc = new Date(values.pickup_date)
      var y = cc.getFullYear()
      var year = moment(new Date(e.pickup_date)).year()
      //console.log(y, year)
      if (y === year) {
        cds.push(e.code.substring(0, e.code.indexOf("/")))
      }
    });

    var cd = cds.length == 0 ? 1 : Math.max.apply(null, cds) + 1
    const codens = cd + " / " + moment(new Date(values.pickup_date)).year()
    //console.log(cd)
    // status 

    let stts = ""
    var pd = new Date(values.pickup_date);
    var today = new Date();
    var rd = new Date(values.return_date);
    let ossan = this.calculateDays(values.pickup_date, values.return_date)
    //console.log("ossan " + ossan)

    if (rd.getTime() > today.getTime() && pd.getTime() > today.getTime()) {
      stts = "Created"
      //console.log(stts)
    }
    if (pd.getTime() < today.getTime() && rd.getTime() > today.getTime()) {
      // //console.log("stattus changed ")
      stts = "Rental"
      //console.log(stts)
    }
    if (today.getTime() > rd.getTime() && today.getTime() > pd.getTime()) {
      stts = "Done"
      //console.log(stts)
    }

  
    const p = vehicles.find(v => v.id === values.vehicle);
    var plt = ""
    var vehicle = ""
    if (p !== undefined) {
      plt = p.plate_number
      vehicle = p.make + " (" + p.plate_number + ")"
    }
    let kra2 = values.vehicle_type
    let trfid = kra2.substring(kra2.indexOf("(") + 1, kra2.indexOf(")"))
   
    let kra3 = values.client
    //let clid = kra3.substring(kra3.indexOf("(") + 1, kra3.indexOf(")"))
    ////console.log(values.client)
    let ikh = this.state.indies.find(e => e.id === values.client);
    let cl = ""
    if (ikh !== undefined) {
      cl = ikh.first_name + " " + ikh.last_name
    }



    let ikha = this.state.tariffs.find(e => e.id === values.vehicle_type);
    let trf = ikha.tariff_name

    var pida = new Date(values.pickup_date).getTime()
    var reda = new Date(values.return_date).getTime()
    this.calculateAmount(values.vehicle_type, ossan)
    
   
   

    if (isEdit) {
      //des=this.state.selectedType.defaultInputValue
      if (reservationPermissions === null || reservationPermissions.update) {
      const updateReservation = {
        id: this.state.reservation.id,
        code: this.state.reservation.code,/* 
        plate_number: plt,
        vehicle: values.vehicle, */
        vehicle: this.state.reservation.vehicle,
        client: cl,
        client_id: values.client,
        pickup_date: values.pickup_date,
        pd: pida,
        pickup_location: values.pickup_location,
        return_date: values.return_date,
        rd: reda,
        return_location: values.return_location,
        vehicle_type: trf,
        tariff_id: values.vehicle_type,
        amount: parseInt(values.amount),
        total_amount: parseInt(parseInt(values.amount) + parseInt(values.frais_divers) + parseInt(values.frais_reprise) + parseInt(values.frais_livraison)),/* 
        rest_amount: restam,
        paid_amount: paidam,
        paid: paid, */
        notes: values.notes,
        days: ossan,
        status: stts,
        frais_livraison: parseInt(values.frais_livraison),
        frais_reprise: parseInt(values.frais_reprise),
        frais_divers: parseInt(values.frais_divers),
        caution: parseInt(values.caution),
        mileage_pickup: parseInt(values.mileage_pickup),
        mileage_return: parseInt(values.mileage_return),
        fuel_level_pickup: values.fuel_level_pickup,
        fuel_level_return: values.fuel_level_return,
        start: values.pickup_date,
        end: values.return_date,
        title: this.state.reservation.vehicle + " (" + cl + ")"

      }
      if (/* this.state.reservation.vehicle == updateReservation.vehicle && */
        this.state.reservation.client == updateReservation.client &&
        this.state.reservation.pickup_date == updateReservation.pickup_date &&
        this.state.reservation.return_date == updateReservation.return_date &&
        this.state.reservation.pickup_location == updateReservation.pickup_location &&
        this.state.reservation.return_location == updateReservation.return_location &&
        this.state.reservation.vehicle_type == updateReservation.vehicle_type &&
        this.state.reservation.amount == updateReservation.amount &&
        this.state.reservation.frais_livraison == updateReservation.frais_livraison &&
        this.state.reservation.frais_divers == updateReservation.frais_divers &&
        this.state.reservation.frais_reprise == updateReservation.frais_reprise &&
        this.state.reservation.caution == updateReservation.caution &&/* 
        this.state.reservation.paid_amount == updateReservation.paid_amount && */
        this.state.reservation.notes == updateReservation.notes &&
        this.state.reservation.mileage_pickup == updateReservation.mileage_pickup &&
        this.state.reservation.mileage_return == updateReservation.mileage_return &&
        this.state.reservation.fuel_level_pickup == updateReservation.fuel_level_pickup &&
        this.state.reservation.fuel_level_return == updateReservation.fuel_level_return) {
        this.setState({ submitLoading: false })
        this.toggle()
      } else {
        this.updateReservation(updateReservation)
      }

    } else {
      this.setState({ modalDenied: true }, () => {
        console.log("Permission denied");
      });
    }
      // update Order
      //onUpdateReservation(updateReservation)
      //toast.success(this.props.t("Reservation updated "))
    } else {
      if (reservationPermissions === null || reservationPermissions.add) {
      const newReservation = {
        code: codens,
        plate_number: plt,
        vehicle: vehicle,
        v_id: values.vehicle,
        client: cl,
        client_id: values["client"],
        pickup_date: values['pickup_date'],
        pd: pida,
        pickup_location: values['pickup_location'],
        return_date: values['return_date'],
        rd: reda,
        return_location: values['return_location'],
        vehicle_type: trf,
        tariff_id: values["vehicle_type"],
        amount: parseInt(values['amount']),
        total_amount: parseInt(parseInt(values['amount']) + parseInt(values['frais_divers']) + parseInt(values['frais_reprise']) + parseInt(values['frais_livraison'])),/* 
        rest_amount: restam,
        paid_amount: paidam,
        paid: paid, */
        notes: values["notes"],
        days: ossan,
        status: stts,
        start: values['pickup_date'],
        end: values['return_date'],
        frais_livraison: parseInt(values['frais_livraison']),
        frais_divers: parseInt(values['frais_divers']),
        frais_reprise: parseInt(values['frais_reprise']),
        caution: parseInt(values['caution']),
        mileage_pickup: parseInt(values['mileage_pickup']),
        mileage_return: parseInt(values['mileage_return']),
        fuel_level_pickup: values['fuel_level_pickup'],
        fuel_level_return: values['fuel_level_return'],
        title: vehicle + " (" + cl + ")"

      }
      this.setState({ reservation: newReservation })
      this.addNewReservation(newReservation)
      //toast.success(this.props.t("Reservation added "))
    } else {
      this.setState({ modalDenied: true }, () => {
        console.log("Permission denied");
      });
    }
    }
    this.setState({ selectedReservation: null })

   

    
    //this.toggle()
  }

  /*  handleValidEventSubmitcategory = (event, values) => {
     const { onAddNewEvent } = this.props
 
     const newEvent = {
       id: Math.floor(Math.random() * 100),
       title: values["title_category"],
       start: new Date(),
       className: values.event_category ? values.event_category + " text-white" : "bg-danger text-white",
     }
     // save new event
     onAddNewEvent(newEvent)
     this.togglecategory()
   } */

  /**
   * On delete event
   */
  handleDeleteEvent = () => {
    const { onDeleteEvent } = this.props
    const { event } = this.state


    onDeleteEvent(event)
    this.setState({ deleteModal: false })
    this.toggle();
  }
  toggleDelete() {
    this.setState(prevState => ({
      isDelete: !prevState.isDelete,
    }))
  }

  deleteReservation = (reservation) => {

    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("reservations")
    const timeline = {
      statusTitle: "Reservation deleted",
      iconClass: "bx bx-spreadsheet h2 text-danger",
      description: "Reservation information has been deleted for ",
      data: reservation.vehicle,
      time: new Date().getTime(),

    }
    collection.doc(reservation.id).delete().then(() => {


      //console.log("Element  deleted!");
      toast.success(this.props.t("Reservation  deleted!"))
      this.addNewTimeline(timeline)
      this.setState({
        success_dlg: true,
        dynamic_title: this.props.t("Deleted"),
        dynamic_description: this.props.t("Element has been deleted."),
      })
    }).catch((error) => {
      console.error("Error removing element: ", error);
      toast.error(this.props.t("Error removing element: "));

    });
  }

  updateReservation = async (reservation) => {
    const { reservations, repairs } = this.state
    var rl = []
    var exist = false
    //console.log(reservation)
    reservations.map(function (item, i) {
      //  if (item.status === "Rental" || item.status === "Created") {
      if (reservation.plate_number === item.plate_number) {
        rl.push({ start_date: item.pd, end_date: item.rd, id: item.id })
      }
      //  }
    })
    repairs.map(function (item, i) {
      if (reservation.plate_number === item.plate_number) {
        rl.push({ start_date: item.sd, end_date: item.ed, id: item.id })
      }
    })
    //console.log("kra ", rl)
    if (rl.length != 0) {
      rl.forEach(e => {
        if (e.id !== reservation.id) {
          if ((e.start_date >= reservation.pd && e.start_date <= reservation.rd) ||
            (reservation.pd >= e.start_date && reservation.pd <= e.end_date)) {
            exist = true

          }
        }

      });
    } else {
      exist = false
    }
    //console.log(exist)
    const timeline = {
      statusTitle: "Reservation updated",
      iconClass: "bx bx-spreadsheet h2 text-warning",
      description: "Reservation information has been updated for ",
      data: reservation.vehicle,
      time: new Date().getTime(),

    }
    this.setState({ submitLoading: true })
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("reservations")
    if (reservation.days !== 0) {
      if (reservation.pd < reservation.rd) {
        if (!exist) {
          var inf = false
          if (reservation.mileage_return !== "" && reservation.fuel_level_return !== "") {
            inf = true
          }
          collection.doc(reservation.id).update({

            /*  plate_number: reservation.plate_number,
             vehicle: reservation.vehicle, */
            client: reservation.client,
            client_id: reservation.client_id,
            pickup_date: reservation.pickup_date,
            pd: reservation.pd,
            pickup_location: reservation.pickup_location,
            return_date: reservation.return_date,
            rd: reservation.rd,
            return_location: reservation.return_location,
            vehicle_type: reservation.vehicle_type,
            tariff_id: reservation.tariff_id,
            amount: reservation.amount,
            total_amount: reservation.total_amount,/* 
            rest_amount: reservation.rest_amount,
            paid_amount: reservation.paid_amount,
            paid: reservation.paid, */
            notes: reservation.notes,
            days: reservation.days,
            status: reservation.status,
            frais_livraison: reservation.frais_livraison,
            frais_divers: reservation.frais_divers,
            frais_reprise: reservation.frais_reprise,
            caution: reservation.caution,
            mileage_pickup: reservation.mileage_pickup,
            mileage_return: reservation.mileage_return,
            fuel_level_pickup: reservation.fuel_level_pickup,
            fuel_level_return: reservation.fuel_level_return,
            start: reservation.pickup_date,
            end: reservation.return_date,
            title: reservation.title,
            r_infos: inf

          }).then(() => {
            //console.log("Reservation  Updated!");
            toast.success(this.props.t("Reservation  Updated!"))
            this.addNewTimeline(timeline)
            this.setState({ submitLoading: false })
            this.toggle()
          }).catch((error) => {
            console.error("Error updating Reservation: ", error);
            toast.error(this.props.t("Error updating Reservation"))
            this.setState({ submitLoading: false })
          });

        } else {
          console.error("Error updating Reservation: Vehicle unavailable ");
          toast.error(this.props.t("Error updating Reservation : Vehicle unavailable"))
          this.setState({ submitLoading: false })
        }
      } else {
        console.error("Error updating Reservation: Invalid dates ");
        toast.error(this.props.t("Error updating Reservation : Invalid dates"))
        this.setState({ submitLoading: false })
      }
    } else {
      console.error("Error Updating Reservation: Number of days cannot be zero ");
      toast.error(this.props.t("Error Updating Reservation : Number of days cannot be zero "))
      this.setState({ submitLoading: false })
    }

  }
  addNewTimeline = async (timeline) => {
    try {
      const { user_id, account_user } = this.state;
      const collection = firebase.firestore().collection("users").doc(user_id).collection("timeline");
      const newDoc = collection.doc();
      const by = account_user.isAdmin ? 'Admin' : `${account_user.first_name} ${account_user.last_name}`;

      await newDoc.set({
        id: newDoc.id,
        statusTitle: timeline.statusTitle,
        iconClass: timeline.iconClass,
        description: timeline.description,
        time: timeline.time,
        data: timeline.data,
        user: by
      });

      //console.log("Timeline  written!");
    } catch (error) {
      console.error("Error adding timeline:", error);
    }
  }
  addNewReservation = async (reservation) => {
    const { reservations, repairs, vehicles } = this.state
    var rl = []
    var exist = false
    var isOffline = false
    vehicles.map(function (item, i) {
      if (reservation.plate_number === item.plate_number) {
        if (item.offline) {
          isOffline = true
        }
      }
    })
    reservations.map(function (item, i) {
      // if (item.status === "Rental" || item.status === "Created") {
      if (reservation.plate_number === item.plate_number) {
        rl.push({ start_date: item.pd, end_date: item.rd, id: item.id })
      }
      //  }
    })
    repairs.map(function (item, i) {
      if (reservation.plate_number === item.plate_number) {
        rl.push({ start_date: item.sd, end_date: item.ed, id: item.id })
      }
    })

    if (rl.length != 0) {
      rl.forEach(e => {
        if ((e.start_date >= reservation.pd && e.start_date <= reservation.rd) ||
          (reservation.pd >= e.start_date && reservation.pd <= e.end_date)) {
          exist = true
        }
      });
    } else {
      exist = false
    }
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("reservations")
    const timeline = {
      statusTitle: "New Reservation created",
      iconClass: "bx bx-spreadsheet h2 text-success",
      description: "Reservation information has been created ",
      data: reservation.vehicle,
      time: new Date().getTime(),

    }
    this.setState({ submitLoading: true })
    if (reservation.days !== 0) {
      if (reservation.pd < reservation.rd) {
        if (!exist) {
          if (!isOffline) {
            var inf = false
            if (reservation.mileage_return !== "" && reservation.fuel_level_return !== "") {
              inf = true
            }
            var newDoc = collection.doc();
            newDoc.set(
              {
                id: newDoc.id,
                code: reservation.code,
                plate_number: reservation.plate_number,
                vehicle: reservation.vehicle,
                v_id: reservation.v_id,
                client: reservation.client,
                client_id: reservation.client_id,
                pickup_date: reservation.pickup_date,
                pd: reservation.pd,
                pickup_location: reservation.pickup_location,
                return_date: reservation.return_date,
                rd: reservation.rd,
                return_location: reservation.return_location,
                vehicle_type: reservation.vehicle_type,
                tariff_id: reservation.tariff_id,
                amount: reservation.amount,
                total_amount: reservation.total_amount,/* 
              rest_amount: reservation.rest_amount,
              paid_amount: reservation.paid_amount,
              paid: reservation.paid, */
                notes: reservation.notes,
                days: reservation.days,
                status: reservation.status,
                frais_livraison: reservation.frais_livraison,
                frais_divers: reservation.frais_divers,
                frais_reprise: reservation.frais_reprise,
                caution: reservation.caution,
                mileage_pickup: reservation.mileage_pickup,
                mileage_return: reservation.mileage_return,
                fuel_level_pickup: reservation.fuel_level_pickup,
                fuel_level_return: reservation.fuel_level_return,
                start: reservation.pickup_date,
                end: reservation.return_date,
                title: reservation.title,
                r_infos: inf,
                createdAt: new Date().getTime()

              }).then(() => {

                //console.log("Reservation  written!");
                toast.success(this.props.t('Reservation added  '))
                this.addNewTimeline(timeline)
                this.setState({ submitLoading: false })
                this.toggle()
              })
              .catch((error) => {
                console.error("Error Adding Reservation infos: ", error);
                toast.error(this.props.t("Error Adding Reservation infos"))
                this.setState({ submitLoading: false })
              });
          } else {
            console.error("Error Adding Reservation: Vehicle is Offline ");
            toast.error(this.props.t("Error Adding Reservation : Vehicle is Offline"))
            this.setState({ submitLoading: false })
          }

        } else {
          console.error("Error Adding Reservation: Vehicle unavailable ");
          toast.error(this.props.t("Error Adding Reservation : Vehicle unavailable"))
          this.setState({ submitLoading: false })
        }
      } else {
        console.error("Error Adding Reservation: Invalid dates ");
        toast.error(this.props.t("Error Adding Reservation : Invalid dates"))
        this.setState({ submitLoading: false })
      }
    } else {
      console.error("Error Adding Reservation: Number of days cannot be zero ");
      toast.error(this.props.t("Error Adding Reservation : Number of days cannot be zero "))
      this.setState({ submitLoading: false })
    }

  }

  updateVehicle = async (vehicle) => {
    //console.log(vehicle.status)
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("vehicles")
    collection.doc(vehicle.id).update({
      status: vehicle.status,
      start_date: vehicle.start_date,
      available_date: vehicle.available_date,
      rented_to: vehicle.rented_to
    }).then(() => {
      //console.log("vehicle  Updated!");
    }).catch((error) => {
      console.error("Error updating vehicle: ", error);
    });
  }
  updateReservation1 = async (reservation) => {
    // //console.log(reservation.status)
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("reservations")
    collection.doc(reservation.id).update({
      status: reservation.status,
    }).then(() => {
      //console.log("reservation  Updated!");
    }).catch((error) => {
      console.error("Error updating vehicle: ", error);
    });
  }
  handleVehicleClick = arg => {
    //console.log(arg)
  }
  /**
   * On category darg event
   */
  /*  onDrag = (event) => {
     event.preventDefault()
   } */

  /**
   * On calendar drop event
   */
  /* onDrop = event => {
    const date = event['date'];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currectDate = new Date();
    const currentHour = currectDate.getHours();
    const currentMin = currectDate.getMinutes();
    const currentSec = currectDate.getSeconds();
    const modifiedDate = new Date(year, month, day, currentHour, currentMin, currentSec);

    const { onAddNewEvent } = this.props
    const draggedEl = event.draggedEl
    const modifiedData = {
      id: Math.floor(Math.random() * 100),
      title: draggedEl.innerText,
      start: modifiedDate,
      className: draggedEl.className,
    }
    onAddNewEvent(modifiedData)
  }
 */
  render() {
    const emptyDataMessage = () => {
      return <Container>
        <Row>
          <Col lg="12">
            {this.state.loading ?
              <div className="text-center">
                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div>
                      <div >
                        <div className="spinner-chase">
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              :
              <div className="text-center">

                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div className="maintenance-img">
                      <img
                        src={maintanence}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
                <h4 className="mt-5">{this.props.t("Let's get started with Medios")}</h4>
                <p className="text-muted">
                  {this.props.t("Start adding your reservations by clicking ")}<h5 className="text-primary"><Link onClick={this.handleReservationClicks} >{this.props.t("New Reservation")}</Link></h5>
                </p>
              </div>
            }
          </Col>
        </Row>
      </Container>


        ;
    }

    const { reservations, repairs, reservation, todaysP, todaysR } = this.state
    const data = reservations;
    const ev = []
    const kk = []
    reservations.map(function (item, i) {
      if (item.status === "Created") {
        item.className = "bg-info text-white p-1 mb-2"
      }
      if (item.status === "Done") {
        item.className = "bg-secondary text-white p-1 mb-2"
      }
      if (item.status === "canceled") {
        item.className = "bg-secondary text-white p-1 mb-2"
      }
      if (item.status === "Rental") {
        item.className = "bg-primary text-white p-1 mb-2"
      }
      ev.push(item)
    })
    repairs.map(function (item, i) {
      if (item.status === "created") {
        item.className = "bg-warning text-white p-1 mb-2"
      }
      if (item.status === "done") {
        item.className = "bg-secondary text-white p-1 mb-2"
      }
      if (item.status === "repair") {
        item.className = "bg-danger text-white p-1 mb-2"
      }

      ev.push(item)
    })

    ////console.log(ev)
    /* let newArray = data.map(function (item) {
      delete item.reservation;
      return item;
    }); */
    //const { selectedType } = this.state
    //const { selectedVehicle } = this.state
    const { selectedClient } = this.state
    // const { selectedTariff } = this.state


    const { isEdit } = this.state

    const { isDelete } = this.state
    const vc = reservation
    const { vehicles } = this.state


    const { indies } = this.state
    const { tariffs } = this.state
    //pagination customization
    const vcList = []
    const vcList2 = []
    const clList = []
    const trfList = []
    vcList.push({ label: "", value: "" })
    vehicles.map(function (item, i) {

      vcList.push({ id: item.id, label: item.make + "(" + item.plate_number + ")", value: item.plate_number })

    })

    clList.push({ label: "", value: "" })
    indies.map(function (item, i) {

      clList.push({ label: item.first_name + " " + item.last_name, value: item.id, dlx: item.dl_expiration_date })

    })


    trfList.push({ label: "", value: "" })
    tariffs.map(function (item, i) {
      trfList.push({ label: item.tariff_name, value: item.id })

    })
    const { events, categories } = this.state;

    const { deleteModal } = this.state;

    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteEvent}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Calendar | Medios - Car Rental Management System")}</title>
          </MetaTags>
          <Container fluid={true}>
            {/* Render Breadcrumb */}
            <Breadcrumbs title={this.props.t("Rental")} breadcrumbItem={this.props.t("Calendar")} />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={3}>
                        <Button
                          color="primary"
                          className="font-16 btn-block btn btn-primary"
                          onClick={this.handleDateClick}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          {this.props.t("New Reservation")}
                        </Button>

                        <div id="external-events" className="mt-3">
                          <h5 className="text-muted">
                            <br />
                            {this.props.t("Events")}

                          </h5>
                          <span className="badge bg-info rounded-pill">{this.props.t("Rental created")}</span>
                          <br />
                          <span className="badge bg-primary rounded-pill">{this.props.t("In rental")}</span>
                          <br />
                          <span className="badge bg-secondary rounded-pill">{this.props.t("Done or cancelled")}</span>
                          <br />
                          <span className="badge bg-warning rounded-pill">{this.props.t("Maintenance created")}</span>
                          <br />
                          <span className="badge bg-danger rounded-pill">{this.props.t("In maintenance")}</span>

                          <h5 className="text-muted">
                            <br />
                            {this.props.t("Vehicles")}

                          </h5>

                          <span className="badge bg-success rounded-pill">{this.props.t("Vehicle available")}</span>
                          <br />
                          <span className="badge bg-warning rounded-pill">{this.props.t("Vehicle rented")}</span>
                          <br />
                          <span className="badge bg-danger rounded-pill">{this.props.t("Vehicle in maintenance")}</span><br /><br />
                          {vehicles.map((vehicle, i) => (

                            <div
                              key={i}
                              className={vehicle.status === 'available' ? "bg-success text-white p-1 mb-2" : (vehicle.status === "unavailable") ? "bg-danger  text-white p-1 mb-2" : "bg-warning text-white p-1 mb-2"}

                              //draggable
                              //onDrag={event => this.onDrag(event, category)}
                              onClick={() => this.handleVehicleClick(vehicle)}
                            >
                              <i className="mdi mdi-checkbox-blank-circle me-2 vertical-middle" />
                              {vehicle.make + " " + vehicle.plate_number}
                            </div>
                          ))}
                        </div>


                      </Col>
                      <Col className="col-lg-9">
                        {/* fullcalendar control */}
                        <FullCalendar
                          ref={this.calendarComponentRef}
                          plugins={[
                            BootstrapTheme,
                            dayGridPlugin,
                            interactionPlugin,
                          ]}
                          slotDuration={"00:15:00"}
                          handleWindowResize={true}
                          themeSystem="bootstrap"
                          headerToolbar={{
                            left: "prev,next today",
                            center: "title",
                            right: "dayGridMonth,dayGridWeek,dayGridDay",
                          }}
                          events={ev}
                          editable={false}
                          droppable={false}
                          dragScroll={false}
                          selectable={true}
                          dateClick={this.handleDateClick}
                          eventClick={this.handleEventClick}
                        //drop={this.onDrop}
                        />
                        <Modal
                          isOpen={this.state.modalDenied}
                          role="alert"
                          autoFocus={true}
                          data-toggle="modalDenied"
                          centered
                          size="md"
                        >
                          <ModalHeader toggle={this.toggleDenied} tag="h4">     </ModalHeader>

                          <Row className="justify-content-center">
                            <Col >

                              <div className="p-2 mt-4 mb-0">
                                <div className="text-center">
                                  <div className="avatar-md mx-auto">
                                    <div className="avatar-title rounded-circle bg-light">
                                      <i className="mdi mdi-shield-lock-outline h1 mt-4 text-primary mdi-48px"></i>
                                    </div>
                                  </div>
                                  <div className="p-2 mt-3 mb-0">
                                    <h4>{this.props.t("Permission Denied !")}</h4>
                                    <br />
                                    <p>


                                      {this.props.t("You do not have the required permissions to perform this action.")}<br /> {this.props.t("Please contact the administrator for assistance.")}

                                    </p>

                                  </div>
                                </div>
                              </div>

                            </Col>
                          </Row>
                        </Modal>
                        {/* New/Edit event modal */}
                        <Modal
                          isOpen={this.state.modal}
                          className={this.props.className}
                          id="event-modal"
                        >
                          <ModalHeader toggle={this.toggle} tag="h4">
                            {!!isEdit ? this.props.t("Edit Reservation") : null}
                            {!!this.state.isShow ? this.props.t("Reservation Infos") : null}
                            {!isEdit && !this.state.isShow ? this.props.t("Add Reservation") : null}
                          </ModalHeader>
                          <ModalBody>
                            {this.state.submitLoading ?
                              <Col>
                                <div>
                                  <div >
                                    <div className="spinner-chase">
                                      <div className="chase-dot"></div>
                                      <div className="chase-dot"></div>
                                      <div className="chase-dot"></div>
                                      <div className="chase-dot"></div>
                                      <div className="chase-dot"></div>
                                      <div className="chase-dot"></div>
                                    </div>
                                  </div>
                                </div>
                                <br />
                                <h6 className='text-center'>{this.props.t("uploading data ...")}</h6>
                              </Col>

                              :

                              <AvForm onValidSubmit={this.handleValidEventSubmit}>
                                <Row>
                                  <Col md="6">
                                    <FormGroup className="mb-4">
                                      <Label> {this.props.t("Pickup date")}</Label>
                                      <AvField
                                        name="pickup_date"
                                        type="datetime-local"
                                        defaultValue={this.state.reservation.pickup_date || ""}
                                        errorMessage={this.props.t("Select a Pickup date")}
                                        disabled={this.state.isShow ? true : false}
                                        onChange={this.handlePDChange}
                                        validate={{
                                          required: { value: true },
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup className="mb-4">
                                      <Label>
                                        {this.props.t("Pickup location")}
                                      </Label>
                                      <AvField
                                        name="pickup_location"
                                        placeholder={this.props.t("Pickup location")}
                                        disabled={this.state.isShow ? true : false}
                                        type="text"
                                        value={this.state.reservation.pickup_location || ""}
                                      />
                                    </FormGroup>
                                  </Col>

                                </Row>
                                <Row>
                                  <Col md="6">
                                    <FormGroup className="mb-4">
                                      <Label> {this.props.t("Return date")}</Label>
                                      <AvField
                                        name="return_date"
                                        type="datetime-local"
                                        defaultValue={this.state.reservation.return_date || ""}
                                        errorMessage={this.props.t("Select a Return date")}
                                        disabled={this.state.isShow ? true : false}
                                        onChange={this.handleRDChange}
                                        validate={{
                                          required: { value: true },
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup className="mb-4">
                                      <Label>
                                        {this.props.t("Return location")}
                                      </Label>
                                      <AvField
                                        name="return_location"
                                        placeholder={this.props.t("Return location")}
                                        disabled={this.state.isShow ? true : false}
                                        type="text"
                                        value={this.state.reservation.return_location || ""}
                                      />
                                    </FormGroup>
                                  </Col>

                                </Row>

                                <Row>

                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <div className="mb-3 select2-container">
                                        <Label>{this.props.t("Rate")}</Label>
                                        <AvField
                                          name="vehicle_type"
                                          type="select"
                                          //placeholder={this.state.reservations.vehicle_type}
                                          onChange={this.handleSelectTariff}
                                          // options={trfList}
                                          value={this.state.reservation.tariff_id}
                                          disabled={this.state.isShow ? true : false}
                                          errorMessage={this.props.t("Select a Rate")}
                                          className="form-control"

                                          validate={{
                                            required: { value: true },
                                          }}
                                        >

                                          {trfList.map((e, key) => {
                                            if (key == 0) {
                                              return <option key={key} value="" disabled>{this.props.t("Rate")}</option>;
                                            } else {
                                              return <option key={key} value={e.value}>{e.label}</option>;
                                            }

                                          })}
                                        </AvField>
                                        <Link to="/tariffs" target="_blank" rel="noopener noreferrer">{this.props.t("Add new Rate")}</Link>
                                      </div>

                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <div className="mb-3 select2-container">
                                        <Label>{this.props.t("Vehicle")}</Label>
                                        {!isEdit && !this.state.isShow ?
                                          <div>
                                            <AvField
                                              name="vehicle"
                                              type="select"
                                              placeholder={""}
                                              onChange={this.handleSelectVehicle}
                                              //options={vcList}
                                              //value={this.state.reservation.vehicle}
                                              disabled={this.state.isShow || !!isEdit ? true : false}
                                              errorMessage={this.props.t("Select a Vehicle")}
                                              className="form-control"

                                              validate={{
                                                required: { value: true },
                                              }}
                                            >

                                              {vcList.map((e, key) => {
                                                if (key == 0) {
                                                  return <option key={key} value="" disabled>{this.props.t("vehicle")}</option>;
                                                } else {
                                                  return <option key={key} value={e.id}>{e.label}</option>;
                                                }
                                              })}
                                            </AvField>
                                            <Link to="/vehicles" target="_blank" rel="noopener noreferrer">{this.props.t("Add new Vehicle")}</Link>
                                          </div>
                                          :
                                          <AvField
                                            name="vehicle"
                                            type="text"
                                            disabled={this.state.isShow || !!isEdit ? true : false}
                                            value={this.state.reservation.vehicle || ""}
                                          />
                                        }
                                      </div>

                                    </FormGroup>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <div className="mb-3 select2-container">
                                        <Label>{this.props.t("Client")}</Label>
                                        <AvField
                                          name="client"
                                          type="select"
                                          //placeholder={this.state.reservations.client}
                                          //onChange={this.handleSelectClient}
                                          //options={clList}

                                          value={this.state.reservation.client_id}
                                          errorMessage={this.props.t("Select a Client")}
                                          className="form-control"
                                          disabled={this.state.isShow ? true : false}
                                          validate={{
                                            required: { value: true },
                                          }}
                                        >

                                          {clList.map((e, key) => {
                                            if (key == 0) {
                                              return <option key={key} value="" disabled>{this.props.t("Client")}</option>;
                                            } else {
                                              return <option key={key} value={e.value}
                                                style={Moment(new Date()).diff(Moment(e.dlx), 'months') <= 0 ? { color: 'orange' } : { color: 'black' }}
                                              >{e.label}</option>;
                                            }

                                          })}
                                        </AvField>
                                        <Link to="/clients" target="_blank" rel="noopener noreferrer" >{this.props.t("Add new Client")}</Link>
                                      </div>

                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup className="mb-3">


                                      <AvField
                                        name="amount"
                                        label={this.props.t("Amount")}
                                        placeholder={this.state.reservation.amount || this.props.t("Total amount")}
                                        errorMessage={this.props.t("Enter Amount")}
                                        type="number"
                                        disabled={this.state.isShow ? true : false}
                                        validate={{ required: { value: true } }}
                                        value={this.state.totalAmount || this.state.reservation.amount}
                                      />
                                      <Link to="#" onClick={this.getAmount} color="danger">{this.props.t("Calculate total")}</Link>

                                    </FormGroup>

                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <AvField
                                        name="frais_livraison"
                                        label={this.props.t("Delivery fee")}
                                        placeholder={this.state.reservation.frais_livraison || this.props.t("Delivery fee")}
                                        type="number"
                                        disabled={this.state.isShow ? true : false}
                                        value={this.state.reservation.frais_livraison || "0"}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <AvField
                                        name="frais_reprise"
                                        label={this.props.t("Return fee")}
                                        placeholder={this.state.reservation.frais_reprise || this.props.t("Return fee")}
                                        type="number"
                                        disabled={this.state.isShow ? true : false}
                                        value={this.state.reservation.frais_reprise || "0"}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <AvField
                                        name="frais_divers"
                                        label={this.props.t("Other expenses")}
                                        placeholder={this.state.reservation.frais_divers || this.props.t("Other expenses")}
                                        type="number"
                                        disabled={this.state.isShow ? true : false}
                                        value={this.state.reservation.frais_divers || "0"}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <AvField
                                        name="caution"
                                        label={this.props.t("Caution")}
                                        placeholder={this.state.reservation.caution || this.props.t("Caution")}
                                        type="number"
                                        disabled={this.state.isShow ? true : false}
                                        value={this.state.reservation.caution || "0"}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                {/* <Row>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <AvField
                                        name="paid_amount"
                                        label={this.props.t("Paid amount")}
                                        placeholder={this.state.reservation.paid_amount || this.props.t("Paid amount")}
                                        type="number"
                                        max={(parseInt(this.state.reservation.amount)+parseInt(this.state.reservation.frais_livraison)+parseInt(this.state.reservation.frais_divers)+parseInt(this.state.reservation.frais_reprise))}
                                        disabled={this.state.isShow || this.state.p ? true : false}
                                        value={this.state.p ? (parseInt(this.state.reservation.amount)+parseInt(this.state.reservation.frais_livraison)+parseInt(this.state.reservation.frais_divers)+parseInt(this.state.reservation.frais_reprise)) : this.state.reservation.paid_amount}
                                      />

                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <Label>{this.props.t("Paid")}</Label>
                                      <div className="form-check mb-3">
                                        <input
                                          name="paid"
                                          className="form-check-input"
                                          type="checkbox"
                                          disabled={this.state.isShow ? true : false}
                                          checked={this.state.p}
                                          onChange={this.checkChange}
                                        />
                                        <Label
                                          className="form-check-label"
                                          htmlFor="defaultCheck1"
                                        >
                                          All Paid
                                        </Label>
                                      </div>

                                    </FormGroup>
                                  </Col>
                                </Row> */}
                                <Row>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <div className="mb-3 select2-container">
                                        <Label>{this.props.t("Mileage pickup")}</Label>
                                        <AvField
                                          name="mileage_pickup"
                                          type="number"
                                          disabled={this.state.isShow ? true : false}
                                          placeholder={this.props.t("Mileage at pickup")}
                                          value={this.state.mile || this.state.reservation.mileage_pickup || 0}
                                          className="form-control"
                                        />
                                      </div>
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <div className="mb-3 select2-container">
                                        <Label>{this.props.t("Mileage return")}</Label>
                                        <AvField
                                          name="mileage_return"
                                          type="number"
                                          disabled={this.state.isShow ? true : false}
                                          placeholder={this.props.t("Mileage at return")}
                                          value={this.state.reservation.mileage_return || 0}
                                          className="form-control"
                                        />
                                      </div>
                                    </FormGroup>
                                  </Col>

                                </Row>
                                <Row>
                                  <Col md="6">

                                    <FormGroup className="mb-3">
                                      <Label >
                                        {this.props.t("Fuel level (Pickup)")}
                                      </Label>
                                      <AvField
                                        name="fuel_level_pickup"
                                        placeholder={this.props.t("Fuel level pickup")}
                                        type="select"
                                        disabled={this.state.isShow ? true : false}
                                        value={this.state.reservation.fuel_level_pickup || ""}
                                      >
                                        <option value="" disabled >{this.props.t("Fuel level")}</option>
                                        <option value="1 / 8">1 / 8</option>
                                        <option value="2 / 8">2 / 8</option>
                                        <option value="3 / 8">3 / 8</option>
                                        <option value="4 / 8">4 / 8</option>
                                        <option value="5 / 8">5 / 8</option>
                                        <option value="6 / 8">6 / 8</option>
                                        <option value="7 / 8">7 / 8</option>
                                        <option value="8 / 8">8 / 8</option>
                                      </AvField>
                                    </FormGroup>

                                  </Col>
                                  <Col md="6">

                                    <FormGroup className="mb-3">
                                      <Label >
                                        {this.props.t("Fuel level (Return)")}
                                      </Label>
                                      <AvField
                                        name="fuel_level_return"
                                        placeholder={this.props.t("Fuel level return")}
                                        type="select"
                                        disabled={this.state.isShow || this.state.reservation.status !== "Done" ? true : false}
                                        value={this.state.reservation.fuel_level_return || ""}
                                      >
                                        <option value="" disabled >{this.props.t("Fuel level")}</option>
                                        <option value="1 / 8">1 / 8</option>
                                        <option value="2 / 8">2 / 8</option>
                                        <option value="3 / 8">3 / 8</option>
                                        <option value="4 / 8">4 / 8</option>
                                        <option value="5 / 8">5 / 8</option>
                                        <option value="6 / 8">6 / 8</option>
                                        <option value="7 / 8">7 / 8</option>
                                        <option value="8 / 8">8 / 8</option>
                                      </AvField>
                                    </FormGroup>

                                  </Col>

                                </Row>

                                <Row>
                                  <Col md="12">

                                    <FormGroup className="mb-3">
                                      <Label >
                                        {this.props.t("Notes")}
                                      </Label>
                                      <AvField
                                        name="notes"
                                        placeholder={this.props.t("Notes")}
                                        disabled={this.state.isShow ? true : false}
                                        type="textarea"
                                        rows="2"
                                        value={this.state.reservation.notes || ""}
                                      />
                                    </FormGroup>
                                  </Col>


                                </Row>
                                <Row>
                                  <Col>

                                    <div className="text-end">


                                      <button
                                        type="button"
                                        className="btn btn-light me-2"
                                        onClick={this.toggle}
                                      >
                                        {this.props.t("Close")}
                                      </button>
                                      {!!isEdit && (<Link to={"/documents/"} /* target="_blank" rel="noopener noreferrer"  */ className="text-info text-start">
                                        <button
                                          type="button"
                                          className="btn btn-info me-2" >

                                          {this.props.t("Documents")}
                                        </button>
                                      </Link>)}
                                      {!!isEdit && (
                                        <button
                                          type="button"
                                          className="btn btn-danger me-2"
                                          onClick={() =>
                                            this.handleDeleteClick(this.state.reservation)
                                          }
                                        >
                                          {this.props.t("Delete")}
                                        </button>
                                      )}
                                      <button
                                        type="submit"
                                        className="btn btn-success save-event"
                                      >
                                        {this.props.t("Save")}
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </AvForm>
                            }
                          </ModalBody>
                        </Modal>
                        {this.state.isDelete ? (
                          <SweetAlert
                            title={this.props.t("Are you sure?")}
                            warning
                            showCancel
                            confirmButtonText={this.props.t("Yes, delete it!")}
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() =>
                              this.handleDeleteReservation(vc)
                            }
                            onCancel={() =>
                              this.setState({
                                isDelete: false,
                              })
                            }
                          >
                            {this.props.t("You won't be able to revert this!")}
                          </SweetAlert>
                        ) : null}

                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {todaysP.length !== 0 ?
          <Row>
            <Col lg="12">
              <TodayPickups />
            </Col>
          </Row>
          : null}
        {todaysR.length !== 0 ?
          <Row>
            <Col lg="12">
              <TodayReturns />
            </Col>
          </Row>
          : null}
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
      </React.Fragment>
    )
  }
}

Calender.propTypes = {
  categories: PropTypes.array,
  vehicles: PropTypes.array,
  className: PropTypes.string,
  events: PropTypes.array,
  onAddNewEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetVehicle: PropTypes.func,
  onGetCategories: PropTypes.func,
  onGetEvents: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  history: PropTypes.any,
  reservations: PropTypes.array,
  indies: PropTypes.array,
  tariffs: PropTypes.array,
  onGetReservation: PropTypes.func,
  onAddNewReservation: PropTypes.func,
  onDeleteReservation: PropTypes.func,
  onUpdateReservation: PropTypes.func,
  onUpdateVehicle: PropTypes.func,
  onGetIndie: PropTypes.func,
  onGetTariff: PropTypes.func,
  className: PropTypes.any,
  t: PropTypes.any,
}

const mapStateToProps = state => ({
  events: state.calendar.events,
  categories: state.calendar.categories,
  reservations: state.reservation.reservations,
  vehicles: state.vehicle.vehicles,
  indies: state.clients.indies,
  tariffs: state.rental.tariffs,
})

const mapDispatchToProps = dispatch => ({
  onGetEvents: () => dispatch(getEvents()),
  onGetCategories: () => dispatch(getCategories()),
  onGetVehicle: () => dispatch(getVehicle()),
  onAddNewEvent: event => dispatch(addNewEvent(event)),
  onUpdateEvent: event => dispatch(updateEvent(event)),
  onDeleteEvent: event => dispatch(deleteEvent(event)),
  onGetIndie: () => dispatch(getIndie()),
  onGetTariff: () => dispatch(getTariff()),
  onGetReservation: () => dispatch(getReservation()),
  onAddNewReservation: reservation => dispatch(addNewReservation(reservation)),
  onUpdateReservation: reservation => dispatch(updateReservation(reservation)),
  onDeleteReservation: reservation => dispatch(deleteReservation(reservation)),
  onUpdateVehicle: vehicle => dispatch(updateVehicle(vehicle)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Calender)));