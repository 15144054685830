import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withRouter } from "react-router-dom"
import { countBy, isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Dropzone from "react-dropzone"
import toastr from "toastr";
import toast, { Toaster } from 'react-hot-toast';


import { withTranslation } from "react-i18next"
import maintanence from "../../../assets/images/coming-soon.svg"
import
paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"
import * as moment from 'moment';
import Select from "react-select"
import jsPDF from "jspdf";
import "jspdf-autotable";
import Moment from 'moment';
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, InputGroup, Input, FormGroup, Label, Button, Card, CardBody, Col, Table, ModalFooter, Container, Row, Modal, Badge, ModalHeader, ModalBody } from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"

import { v4 as uuid } from "uuid"
import imageCompression from 'browser-image-compression';


// Add the Firebase products that you want to use
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  getInssurance,
  addNewInssurance,
  updateInssurance,
  deleteInssurance,
  getVehicle,
} from "store/actions"


import exportFromJSON from 'export-from-json'
import { isThisExpression } from "@babel/types";
import { vehicle12 } from "assets/images/vehicle";
import { inssurances } from "common/data";

class Inssurances extends Component {
  constructor(props) {
    super(props)
    this.state = {
      p_loading: false,
      modalDenied: false,
      inssurancePermissions: null,
      user_id: "",
      selectedPeriod: "day",
      selectedVehicleName: "All Vehicles",
      selectedVehicle1: 1,
      selectedDate: "",
      selectedTS: null,
      selectedOption: "todo",
      modal1: false,
      currentYear: new Date().getFullYear(),
      ibadl: false,
      loading: true,
      submitLoading: false,
      isShow: false,
      changed: false,
      vcchanged: false,
      wschanged: false,
      gender: "",
      viewmodal: false,
      modal: false,
      isDelete: false,
      no_data: false,
      success_dlg: false,
      dynamic_title: '',
      dynamic_description: '',
      vc: "",
      selectedType: null,
      selectedVehicle: null,
      inssurances: [],
      inssurance: [],
      vehicles: [],
      date_birth: new Date(),
      InssuranceColumns: [

        {
          dataField: "vehicle",
          text: this.props.t("Vehicle"),
          sort: true,
          formatter: (cellContent, row) => (
            <Link to="#" className="text-body fw-bold">
              {row.vehicle}
            </Link>
          ),
        },
        {
          dataField: "createdAt",
          text: this.props.t("Date created"),
          sort: true,
          formatter: (cellContent, row) => (
            <>

              {" "}
              {moment(row.createdAt).format('YYYY-MM-DDTHH:mm')}
            </>
          ),
        },

        {
          dataField: "start_date",
          text: this.props.t("Start Date"),
          sort: true,
        },
        {
          dataField: "end_date",
          text: this.props.t("End Date"),
          sort: true,
          formatter: (cellContent, row) => (

            <>
              <Link to="#" className={Moment(row.end_date).diff(Moment(new Date()), 'days') >= 30 ? "text-success" : (Moment(row.end_date).diff(Moment(new Date()), 'days') > 0 ? "text-warning" : "text-danger")}>
                <i className="far fa-clock me-1" />
              </Link>{" "}

              {row.end_date}
            </>
          ),
        },
        {
          dataField: "payment_date",
          text: this.props.t("Payment date"),
          sort: true,
        },
        {
          dataField: "type",
          text: this.props.t("Inssurance Type"),
          sort: true,

        },
        {
          dataField: "amount",
          text: this.props.t("Total amount"),
          sort: true,
          formatter: (cellContent, row) => (
            <>
              {" "}
              {row.amount} dh
            </>
          ),
        },

        {
          dataField: "view",
          isDummyField: true,
          text: this.props.t("View Details"),
          sort: true,
          formatter: (cellContent, inssurance) => (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => this.handleViewClick(inssurance)}
            >
              {this.props.t("View Details")}
            </Button>
          ),
        },
        {
          dataField: "action",
          isDummyField: true,
          text: this.props.t("Action"),
          formatter: (cellContent, inssurance) => (
            <>



              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => this.handleInssuranceClick(inssurance)} />
                </Link>
                <Link to="#" className="text-danger">
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.handleDeleteClick(inssurance)} />
                </Link>
              </div>
            </>
          ),
        },
      ],
      selectedFiles: []
    }
    this.toggleDenied = this.toggleDenied.bind(this)
    this.handleInssuranceClick = this.handleInssuranceClick.bind(this)
    this.toggle = this.toggle.bind(this)
    this.handleValidInssuranceSubmit = this.handleValidInssuranceSubmit.bind(this)
    this.handleInssuranceClicks = this.handleInssuranceClicks.bind(this)
    this.toLowerCase1 = this.toLowerCase1.bind(this)
    this.toggleDelete = this.toggleDelete.bind(this)
    this.handleViewClick = this.handleViewClick.bind(this)
    this.handleSelectType = this.handleSelectType.bind(this)
    this.handleSelectVehicle = this.handleSelectVehicle.bind(this)
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
    this.toggleIsShow = this.toggleIsShow.bind(this)

    this.toggle_modal = this.toggle_modal.bind(this)
    this.onValueChange = this.onValueChange.bind(this)
    this.handleValidEventSubmit = this.handleValidEventSubmit.bind(this)
    this.handleSelectVehicleFilter = this.handleSelectVehicleFilter.bind(this)
  }
  toggleDenied() {
    this.setState(prevState => ({
      modalDenied: !prevState.modalDenied,
    }))
  }
  handleDeleteClick = (inssurance) => {

    const { inssurancePermissions, p_loaded } = this.state;

    if (!p_loaded) {
      return; // Exit early if permissions are not yet loaded
    }

    if (inssurancePermissions === null || inssurancePermissions.delete) {
      this.setState({ isDelete: true, vc: inssurance });
    } else {
      this.setState({ modalDenied: true }, () => {
        console.log("Permission denied");
      });
    }

  };
  onValueChange(event) {
    const { notifs } = this.state
    this.setState({
      selectedOption: event.target.id
    });
    ////console.log(event.target.id)

  }
  dateChanged = d => {
    /*  const { vehicles, rdchanged, RD } = this.state
     this.setState({ PD: d.target.value })*/
    this.setState({
      selectedTS: moment(d.target.value, 'YYYY-MM-DD').valueOf(),
      selectedDate: d.target.value,
      selectedOption: "dia"
    })

    //console.log(d.target.value)
  }
  monthChanged = d => {

    this.setState({
      selectedTS: moment(d.target.value, 'YYYY-MM').valueOf(),
      selectedDate: d.target.value,
      selectedOption: "mes"
    })

    //console.log(d.target.value)
  }
  toggle_modal() {
    this.setState(prevState => ({
      modal1: !prevState.modal1,
    }))
    this.removeBodyCss()
  }

  removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  handlePeriodChange = (e) => {
    this.setState({
      selectedPeriod: e.target.value
    })
    //console.log(e.target.value);
  };
  handleValidEventSubmit = (e, values) => {

    //console.log(values)
    if (values.period === "day") {
      this.setState({
        selectedTS: moment(values.date, 'YYYY-MM-DD').valueOf(),
        selectedOption: "dia",
        selectedDate: values.date,
      })
    }
    if (values.period === "month") {
      this.setState({
        selectedTS: moment(values.month, 'YYYY-MM').valueOf(),
        selectedOption: "mes",
        selectedDate: values.month,
      })
    }
    if (values.period === "year") {
      this.setState({
        selectedTS: moment(values.year, 'YYYY').valueOf(),
        selectedOption: "ano",
        selectedDate: values.year,
      })
    }



    this.toggle_modal()
  }
  handleSelectVehicleFilter = (selectedVehicle, value) => {
    const { vehicles } = this.state
    /*  let kra = selectedVehicle.target.value
     let plt = kra.substring(kra.indexOf("(") + 1, kra.indexOf(")")) */
    //console.log(value)
    if (value === 'All vehicles') {
      this.setState({

        selectedVehicleName: "All Vehicles",
        selectedVehicle1: 1
      })
    } else {
      const p = vehicles.find(v => v.id === value);
      var plt = ""
      if (p !== undefined) {
        plt = p.plate_number
      }
      let m = 0

      const name = p.make + " " + p.model + " ( " + p.plate_number + " )"
      //console.log(name)
      vehicles.map((e, key) => {

        if (e.plate_number === plt) {
          m = e.mileage
        }
      })
      this.setState({
        mile: m,
        selectedVehicleName: name,
        selectedVehicle1: value
      })
    }

  }

  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.setState({ selectedFiles: files })
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  toLowerCase1(str) {
    return str.toLowerCase();
  }
  handleSelectType = selectedType => {

    this.setState({ selectedType: selectedType })
    this.setState({ changed: true })
  }
  handleSelectVehicle = selectedVehicle => {

    this.setState({ selectedVehicle: selectedVehicle })
    this.setState({ vcchanged: true })
  }

  componentDidMount() {
    const { inssurances, onGetInssurance } = this.props
    if (inssurances && !inssurances.length) {
      //onGetInssurance()
      this.getInssurance()
    }
    this.setState({ inssurances })
    const { vehicles, onGetVehicle } = this.props
    if (vehicles && !vehicles.length) {
      // onGetVehicle()
      this.getVehicle()
    }
    this.setState({ vehicles })
    this.getInssurancePermissions()
  }
  async getInssurancePermissions() {
    try {
      this.setState({ p_loaded: false })
      firebase.auth().onAuthStateChanged(async user => {
        if (user) {
          const userId = user.uid;
          // Reference to the user document
          const userDocRef = firebase.firestore().collection("users").doc(userId);

          // Fetch the user document
          const userSnapshot = await userDocRef.get();

          if (!userSnapshot.exists) {
            this.setState({ p_loaded: true })
            console.log("User document does not exist.");
            return null; // Return null if user document doesn't exist
          }

          const userData = userSnapshot.data();

          // Check if the user is an admin
          if (userData.isAdmin) {
            this.setState({ p_loaded: true })
            //console.log("User is an admin; permissions are not restricted.");
            return null; // Return null or handle differently for admins
          }

          // Reference to the permissions document for 'clients'
          const permissionsDocRef = userDocRef.collection("permissions").doc("inssurance");

          // Fetch the document
          const permissionsSnapshot = await permissionsDocRef.get();

          if (permissionsSnapshot.exists) {
            const inssurancePermissions = permissionsSnapshot.data();
            /// console.log("Client Permissions:", clientPermissions);
            this.setState({ inssurancePermissions });
            this.setState({ p_loaded: true })
            return inssurancePermissions; // Return the permissions as an object
          } else {
            this.setState({ p_loaded: true })
            console.log("No permissions found for 'inssurance'.");
            return null; // Return null if no permissions exist
          }
        } else {
          this.setState({ p_loaded: true })
          console.log("Not authenthicated")
        }
      })

    } catch (error) {
      this.setState({ p_loaded: true })
      console.error("Error fetching inssurance permissions:", error);
      throw error;
    }
  }
  // eslint-disable-next-line no-unused-vars
  /* componentDidUpdate(prevProps, prevState, snapshot) {
    const { inssurances } = this.state
    const { vehicles } = this.state

    if (!isEmpty(inssurances) && size(prevProps.inssurances) !== size(inssurances)) {
      //this.setState({ inssurances: {}, isEdit: false })
    }
    if (!isEmpty(vehicles) && size(prevProps.vehicles) !== size(vehicles)) {
     // this.setState({ vehicles: {}, isEdit: false })
    }
    
  } */
  getInssurance = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeInssurances) {
      this.unsubscribeInssurances();
    }

    this.unsubscribeInssurances = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userInssurancesCollectionRef = usersCollection.doc(userId).collection("inssurances");
            this.setState({
              user_id: userId,
              account_user: userDoc.data()
            })

            this.unsubscribeInssurances = userInssurancesCollectionRef.onSnapshot(snapshot => {
              let t = [];
              snapshot.docs.forEach(doc => {
                t.push(doc.data());
              });

              this.setState({
                loading: false,
                inssurances: t
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            this.setState({
              loading: false,
              inssurances: []
            });
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          loading: false,
          inssurances: []
        });
      }
    });

  }

  getVehicle = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeVehicles) {
      this.unsubscribeVehicles();
    }

    this.unsubscribeVehicles = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userVehiclesCollectionRef = usersCollection.doc(userId).collection("vehicles");
            this.setState({
              user_id: userId
            })
            this.unsubscribeVehicles = userVehiclesCollectionRef.onSnapshot(snapshot => {
              let vehicles = [];
              snapshot.docs.forEach(doc => {
                vehicles.push(doc.data());
              });

              this.setState({
                vehicles: vehicles
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            this.setState({
              vehicles: []
            });
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          vehicles: []
        });
      }
    });
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
      ibadl: false,
    }))
  }
  toggleDelete() {
    this.setState(prevState => ({
      isDelete: !prevState.isDelete,
    }))
  }

  handleInssuranceClicks = () => {
    const { inssurancePermissions, p_loaded } = this.state;

    if (!p_loaded) {
      return; // Exit early if permissions are not yet loaded
    }

    if (inssurancePermissions === null || inssurancePermissions.add) {
      this.setState({ inssurance: [], isEdit: false, isShow: false, selectedVehicle: null, selectedType: null }, () => {
        this.toggle(); // Ensure toggle is called after state updates
      });
    } else {
      this.setState({ modalDenied: true }, () => {
        console.log("Permission denied");
      });
    }


  }

  // eslint-disable-next-line no-unused-vars
  handleTableChange = (type, { page, searchText }) => {
    const { inssurances } = this.props
    this.setState({
      inssurances: inssurances.filter(inssurance =>
        Object.keys(inssurance).some(
          key =>
            typeof inssurance[key] === "string" &&
            inssurance[key].toLowerCase().includes(searchText.toLowerCase())
        )
      ),
    })
  }

  toggleViewModal = () => {
    this.setState(prevState => ({
      viewmodal: !prevState.viewmodal,
    }))
  }

  toggleIsShow = () => {
    this.setState(prevState => ({
      isShow: !prevState.isShow,
    }))
  }


  /* Insert,Update Delete data */

  handleDeleteInssurance = (inssurance) => {


    /* const { onDeleteInssurance } = this.props
    if (inssurance['code'] === '') {
      //
    } else { */
    // onDeleteInssurance(inssurance)
    this.deleteInssurance(inssurance)
    this.toggleDelete()
    // }

  }

  handleViewClick = arg => {
    const inssurance = arg
    this.setState({ selectedType: { label: inssurance.type, value: inssurance.type } })
    this.setState({ selectedVehicle: { label: inssurance.vehicle, value: inssurance.plate_number } })

    this.setState({
      inssurance: {
        // code: inssurance.code,
        //label: inssurance.label,
        vehicle: inssurance.vehicle,
        plate_number: inssurance.plate_number,
        start_date: inssurance.start_date,
        end_date: inssurance.end_date,
        payment_date: inssurance.payment_date,
        amount: inssurance.amount,
        inssurance_company: inssurance.inssurance_company,
        notes: inssurance.notes,
        type: inssurance.type,
        /* reciept:inssurance.reciept,
        invoice:inssurance.invoice */
      },

      isShow: true,
      isEdit: false

    })

    //this.toggleIsShow()
    this.toggle()

    //this.toggleViewModal()
  }


  handleInssuranceClick = arg => {

    const { inssurancePermissions, p_loaded } = this.state;

    if (!p_loaded) {
      return; // Exit early if permissions are not yet loaded
    }

    if (inssurancePermissions === null || inssurancePermissions.update) {
      const inssurance = arg

      this.setState({ selectedType: { label: inssurance.type, value: inssurance.type } })
      this.setState({ selectedVehicle: { label: inssurance.vehicle, value: inssurance.plate_number } })
      this.setState({
        inssurance: {
          id: inssurance.id,
          //label: inssurance.label,
          vehicle: inssurance.vehicle,
          plate_number: inssurance.plate_number,
          start_date: inssurance.start_date,
          end_date: inssurance.end_date,
          payment_date: inssurance.payment_date,
          amount: inssurance.amount,
          inssurance_company: inssurance.inssurance_company,
          notes: inssurance.notes,
          type: inssurance.type,
          /* reciept:inssurance.reciept,
          invoice:inssurance.invoice */
        },
        isEdit: true,
        isShow: false,
      })

      this.toggle()
    } else {
      this.setState({ modalDenied: true }, () => {
        console.log("Permission denied");
      });
    }



  }

  /**
   * Handling submit Order on Order form
   */
  handleValidInssuranceSubmit = (e, values) => {
    const { onAddNewInssurance, onUpdateInssurance } = this.props
    const { isEdit, inssurances, selectedInssurance, vehicles } = this.state
    /* const cds = []
    this.props.inssurances.map(spl =>
      cds.push(spl.code)
    )
    const cd =cds.length==0?1:Math.max.apply(null,cds)+1
 */

    var sd = new Date(values.start_date).getTime()
    var ed = new Date(values.end_date).getTime()
    var p_d = new Date(values.payment_date).getTime()
    const now = new Date().getTime();
    const tenDays = ed - 10 * 24 * 60 * 60 * 1000;

    const minusone = now - 1 * 24 * 60 * 60 * 1000;

    console.log(tenDays, now, minusone)
    console.log(tenDays < now && tenDays >= minusone)
    let ty = ""
    if (this.state.selectedType != null) {
      ty = this.state.selectedType.label
      if (this.state.changed) {
        ty = this.state.selectedType.label
        this.setState({ changed: false })
      } else {
        ty = this.state.selectedType.label
      }
    }

    /* let veh="" 
    if(this.state.selectedVehicle!=null){
      veh= this.state.selectedVehicle.label
    if(this.state.vcchanged){
      veh= this.state.selectedVehicle.label
      this.setState({vcchanged:false})
    }else{
      veh=this.state.selectedVehicle.label
    }
  } */
    /* let plt="" 
    if(this.state.selectedVehicle!=null){
      plt= this.state.selectedVehicle.value
    if(this.state.vcchanged){
      plt= this.state.selectedVehicle.value
      this.setState({vcchanged:false})
    }else{
      plt=this.state.selectedVehicle.value
    }
  } */

    const p = vehicles.find(v => v.id === values.vehicle);
    var plt = ""
    var vehicle = ""
    if (p !== undefined) {
      plt = p.plate_number
      vehicle = p.make + " (" + p.plate_number + ")"
    }
    //console.log(plt)
    //console.log(this.state.inssurance)

    var am = parseInt(values.amount)

    /* this.setState({
      gender:this.state.staffs.gender
    }) */
    //console.log(this.state.inssurance.vehicle)
    if (isEdit) {
      //des=this.state.selectedType.defaultInputValue

      const updateInssurance = {

        id: this.state.inssurance.id,
        //label: values.label.trim(),
        vehicle: this.state.inssurance.vehicle,
        v_id: values.vehicle,
        tenDays: tenDays,
        /* vehicle: values.vehicle.trim(),
        plate_number:plt, */
        start_date: values.start_date.trim(),
        end_date: values.end_date.trim(),
        payment_date: values.payment_date.trim(),
        sd: sd,
        ed: ed,
        p_d: p_d,
        amount: am,
        inssurance_company: values.inssurance_company.trim(),
        notes: values.notes.trim(),
        type: ty
        /*   reciept:values.reciept,
          invoice:values.invoice */


      }

      // update Order
      //onUpdateInssurance(updateInssurance)
      if (this.state.inssurance.label == updateInssurance.label &&/* 
        this.state.inssurance.vehicle== updateInssurance.vehicle&& */
        this.state.inssurance.start_date == updateInssurance.start_date &&
        this.state.inssurance.end_date == updateInssurance.end_date &&
        this.state.inssurance.payment_date == updateInssurance.payment_date &&
        this.state.inssurance.amount == updateInssurance.amount &&
        this.state.inssurance.inssurance_company == updateInssurance.inssurance_company &&
        this.state.inssurance == updateInssurance.type &&
        this.state.inssurance.notes == updateInssurance.notes) {
        this.setState({ submitLoading: false })
        this.toggle()
      } else {
        this.updateInssurance(updateInssurance)
      }
    } else {

      const newInssurance = {

        //label: values["label"],
        vehicle: vehicle,
        plate_number: plt,
        v_id: values.vehicle,
        start_date: values['start_date'],
        end_date: values['end_date'],
        payment_date: values['payment_date'],
        sd: sd,
        ed: ed,
        p_d: p_d,
        tenDays: tenDays,
        amount: am,
        inssurance_company: values['inssurance_company'],
        notes: values["notes"],
        type: ty
        /* reciept:values['reciept'],
        invoice:values['invoice'], */
      }
      ////console.log(des)
      // save new Order
      //onAddNewInssurance(newInssurance)
      this.setState({ inssurance: newInssurance })
      this.addNewInssurance(newInssurance)
      //toast.success(this.props.t("Inssurance added "))
    }
    this.setState({ selectedInssurance: null })
    //this.toggle()
  }

  deleteInssurance = (inssurance) => {

    ////console.log(tax)
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("inssurances")
    const timeline = {
      statusTitle: "Inssurance deleted",
      iconClass: "bx bx-shield h2 text-danger",
      description: "Inssurance information has been deleted for ",
      data: inssurance.vehicle,
      time: new Date().getTime(),

    }
    collection.doc(inssurance.id).delete().then(() => {


      //console.log("Element  deleted!");
      toast.success(this.props.t("Inssurance  deleted!"))
      this.addNewTimeline(timeline)
      this.setState({
        success_dlg: true,
        dynamic_title: this.props.t("Deleted"),
        dynamic_description: this.props.t("Element has been deleted."),
      })
    }).catch((error) => {
      console.error("Error removing element: ", error);
      toast.error(this.props.t("Error removing element"))

    });
  }

  updateInssurance = async (inssurance) => {

    this.setState({ submitLoading: true })
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("inssurances")

    const timeline = {
      statusTitle: "Inssurance updated",
      iconClass: "bx bx-shield h2 text-warning",
      description: "Inssurance information has been updated for ",
      data: inssurance.vehicle,
      time: new Date().getTime(),

    }

    collection.doc(inssurance.id).update({

      // inssurance.label,
      start_date: inssurance.start_date,
      end_date: inssurance.end_date,
      payment_date: inssurance.payment_date,
      ed: inssurance.ed,
      sd: inssurance.sd,
      p_d: inssurance.p_d,
      tenDays: inssurance.tenDays,
      amount: inssurance.amount,
      inssurance_company: inssurance.inssurance_company,
      notes: inssurance.notes,
      type: inssurance.type,


    }).then(() => {
      //console.log("Document Updated!");
      toast.success(this.props.t("Document Updated!"))
      this.addNewTimeline(timeline)
      this.setState({ submitLoading: false })
      this.toggle()
    }).catch((error) => {
      console.error("Error updating document: ", error);
      toast.error(this.props.t("Error updating document"))
      this.setState({ submitLoading: false })
    });


  }
  addNewTimeline = async (timeline) => {
    try {
      const { user_id, account_user } = this.state;
      const collection = firebase.firestore().collection("users").doc(user_id).collection("timeline");
      const newDoc = collection.doc();
      const by = account_user.isAdmin ? 'Admin' : `${account_user.first_name} ${account_user.last_name}`;

      await newDoc.set({
        id: newDoc.id,
        statusTitle: timeline.statusTitle,
        iconClass: timeline.iconClass,
        description: timeline.description,
        time: timeline.time,
        data: timeline.data,
        user: by
      });

      //console.log("Timeline  written!");
    } catch (error) {
      console.error("Error adding timeline:", error);
    }
  }
  addNewInssurance = async (inssurance) => {
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("inssurances")
    const timeline = {
      statusTitle: "Inssurance added",
      iconClass: "bx bx-shield h2 text-success",
      description: "Inssurance information has been added ",
      data: inssurance.vehicle,
      time: new Date().getTime(),

    }
    this.setState({ submitLoading: true })


    var newDoc = collection.doc();
    newDoc.set(
      {
        id: newDoc.id,
        //label: inssurance.label,
        vehicle: inssurance.vehicle,
        plate_number: inssurance.plate_number,
        start_date: inssurance.start_date,
        end_date: inssurance.end_date,
        payment_date: inssurance.payment_date,
        ed: inssurance.ed,
        sd: inssurance.sd,
        p_d: inssurance.p_d,
        tenDays: inssurance.tenDays,
        amount: inssurance.amount,
        v_id: inssurance.v_id,
        inssurance_company: inssurance.inssurance_company,
        notes: inssurance.notes,
        type: inssurance.type,
        createdAt: new Date().getTime(),
      }).then(() => {

        //console.log("Inssurance  written!");
        toast.success(this.props.t('Inssurance added '))
        this.addNewTimeline(timeline)
        this.setState({ submitLoading: false })
        this.toggle()
      })
      .catch((error) => {
        console.error("Error Adding inssurance infos: ", error);
        toast.error(this.props.t("Error Adding inssurance infos"))
        this.setState({ submitLoading: false })
      });

  }


  handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format('DD MMM Y');
    return date1;
  }

  render() {
    const emptyDataMessage = () => {
      return <Container>
        <Row>
          <Col lg="12">
            {this.state.loading ?
              <div className="text-center">
                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div>
                      <div >
                        <div className="spinner-chase">
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              :
              <div className="text-center">

                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div className="maintenance-img">
                      <img
                        src={maintanence}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
                <h4 className="mt-5">{this.props.t("Let's get started with Medios")}</h4>
                <p className="text-muted">
                  {this.props.t("Start adding your inssurance informations by clicking ")}<h5 className="text-primary"><Link onClick={this.handleInssuranceClicks} >{this.props.t("New Inssurance")}</Link></h5>
                </p>
              </div>
            }
          </Col>
        </Row>
      </Container>


        ;
    }

    const { inssurancePermissions, currentYear, selectedVehicle1, selectedOption, selectedTS, selectedDate, selectedPeriod, inssurances } = this.state
    const { selectedType } = this.state
    const { selectedVehicle } = this.state

    var inss
    inss = inssurances
    const data = inss;
    //console.log(selectedOption)
    switch (selectedOption) {
      case 'todo':
        var i = []
        inssurances.map(function (item) {
          if (selectedVehicle1 === 1) {
            i.push(item)
          }
          if (item.v_id === selectedVehicle1) {
            i.push(item)
          }
        });

        inss = i
        break;
      case 'hoy':
        var i = []
        inssurances.map(function (item) {
          var sd = moment(new Date()).format('MM-DD-YYYY')
          var ds = moment(item.createdAt).format("MM-DD-YYYY");
          var t = moment(ds)
          var s = moment(sd)
          if (s.isSame(t, 'day') && selectedVehicle1 === 1) {
            i.push(item)
          }
          if (s.isSame(t, 'day') && item.v_id === selectedVehicle1) {
            i.push(item)
          }
        });

        inss = i
        break;
      case 'sempa':
        var i = []
        inssurances.map(function (item) {
          if (moment(new Date().getTime()).isSame(moment(item.createdAt), 'week') && selectedVehicle1 === 1) {
            i.push(item)
          }
          if (moment(new Date().getTime()).isSame(moment(item.createdAt), 'week') && item.v_id === selectedVehicle1) {
            i.push(item)
          }
        });

        inss = i
        break;
      case 'mespa':
        var i = []
        inssurances.map(function (item) {
          if (moment(new Date().getTime()).isSame(moment(item.createdAt), 'month') && selectedVehicle1 === 1) {
            i.push(item)
          }
          if (moment(new Date().getTime()).isSame(moment(item.createdAt), 'month') && item.v_id === selectedVehicle1) {
            i.push(item)
          }
        });


        inss = i
        break;
      case 'dia':
        //console.log("diaaaaa")
        var i = []
        inssurances.map(function (item) {
          var sd = moment(selectedTS).format('MM-DD-YYYY')
          var ds = moment(item.createdAt).format("MM-DD-YYYY");
          var t = moment(ds)
          var s = moment(sd)
          if (s.isSame(t, 'day') && selectedVehicle1 === 1) {
            i.push(item)
          }
          if (s.isSame(t, 'day') && item.v_id === selectedVehicle1) {
            i.push(item)
          }
        });
        inss = i
        break;
      case 'mes':
        //console.log("meeeees")
        var i = []
        inssurances.map(function (item) {
          if (moment(selectedTS).isSame(moment(item.createdAt), 'month') && selectedVehicle1 === 1) {
            i.push(item)
          }
          if (moment(selectedTS).isSame(moment(item.createdAt), 'month') && item.v_id === selectedVehicle1) {
            i.push(item)
          }
        });

        inss = i
        break;
      case 'ano':
        //console.log("anooooo")
        var i = []
        inssurances.map(function (item) {
          if (moment(selectedTS).isSame(moment(item.createdAt), 'year') && selectedVehicle1 === 1) {
            i.push(item)
          }
          if (moment(selectedTS).isSame(moment(item.createdAt), 'year') && item.v_id === selectedVehicle1) {
            i.push(item)
          }
        });


        inss = i
        break;
      default:

    }
    let dataxcl = []
    inss.map(function (item) {
      delete item.inssurance;
      dataxcl.push({
        //label: item.label,
        vehicle: item.vehicle,
        plate_number: item.plate_number,
        start_date: item.start_date,
        end_date: item.end_date,
        payment_date: item.payment_date,
        amount: item.amount,
        inssurance_company: item.inssurance_company,
        notes: item.notes,
        type: item.type,
      })
    });

    const { SearchBar } = Search
    const { isEdit } = this.state
    const { isDelete } = this.state
    const { vc } = this.state
    const { vehicles } = this.state
    //pagination customization
    const vcList = []
    vcList.push({ label: "", value: "" })
    vehicles.map(function (item, i) {
      vcList.push({ id: item.id, label: item.make + ' (' + item.plate_number + ')', value: item.plate_number })

    })
    const vcList1 = []
    vcList1.push({ label: "All vehicles", value: "All" })
    vehicles.map(function (item, i) {

      vcList1.push({ id: item.id, label: item.make + "(" + item.plate_number + ")", value: item.plate_number })

    })


    const defaultSorted = [{
      dataField: 'createdAt',
      order: 'desc'
    }];

    const selectRow = {
      mode: 'checkbox',
    };

    const optionType =
      [
        { label: this.props.t("civil liability"), value: "civil liability" },
        { label: this.props.t("damage and collision"), value: "damage and collision" },
        { label: this.props.t("all risks"), value: "all risks" },
        { label: this.props.t("Other"), value: "Other" },
      ]

    function ExportToExcel() {
      exportFromJSON({ data: dataxcl, fileName: 'Inssurances', exportType: exportFromJSON.types.xls })
    }
    function exportPDF() {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape

      const marginLeft = 20;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);

      const title = "Inssurances Infos";
      const headers = [["label", "Vehicle", "Start date", "End date", "Payment date", "Inssurance Company", "Type", "amount", "Details"]];

      const dt = dataxcl.map(elt => [elt.label, elt.vehicle, elt.start_date, elt.end_date, elt.payment_date, elt.inssurance_company, elt.type, elt.amount, elt.details]);


      let content = {
        startY: 50,
        head: headers,
        body: dt
      };

      doc.text(title, marginLeft, 20);
      doc.autoTable(content);
      doc.save("Maintenance.pdf")
    }
    function printPDF() {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape

      const marginLeft = 20;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);

      const title = "Inssurances Infos";
      const headers = [["label", "Vehicle", "Start date", "End date", "Payment date", "Inssurance Company", "Type", "amount", "Details"]];

      const dt = dataxcl.map(elt => [elt.label, elt.vehicle, elt.start_date, elt.end_date, elt.payment_date, elt.inssurance_company, elt.type, elt.amount, elt.details]);


      let content = {
        startY: 50,
        head: headers,
        body: dt
      };

      doc.text(title, marginLeft, 20);
      doc.autoTable(content);
      var string = doc.output('datauristring');
      var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
      var x = window.open();
      x.document.open();
      x.document.write(embed);
      x.document.close();

    }

    return (

      <React.Fragment>


        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Inssurance | Medios - Car Rental Management System")}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title={this.props.t("Fleet")} breadcrumbItem={this.props.t("Inssurance")} />
            {this.state.success_dlg ? (
              <SweetAlert
                success
                title={this.state.dynamic_title}
                onConfirm={() => this.setState({ success_dlg: false })}
              >
                {this.state.dynamic_description}
              </SweetAlert>
            ) : null}
            <Row className="mb-2" >

              <Row>

                <Col dm="3" className="text-sm-center mb-2">
                  <AvForm>
                    <AvField
                      name="title"
                      type="select"
                      onChange={this.handleSelectVehicleFilter}
                      validate={{
                        required: { value: true },
                      }}

                    >
                      {vcList1.map((e, key) => {

                        return <option key={key} value={e.id}>{e.label}</option>;

                      })}</AvField>
                  </AvForm>



                </Col>
                <Col md="6" >
                  <div className="text-end " >

                    <Col   >

                      <div className="btn-group me-2" role="group" aria-label="Basic radio toggle button group" onChange={this.onChangeValue}>
                        <input type="radio" className="btn-check" name="btnradio" id="todo" onClick={this.onValueChange} autoComplete="off" defaultChecked />
                        <label className="btn btn-primary" htmlFor="todo">{this.props.t("All")}</label>

                        <input type="radio" className="btn-check" name="btnradio" id="hoy" onClick={this.onValueChange} autoComplete="off" />
                        <label className="btn btn-primary" htmlFor="hoy">{this.props.t("Today")}</label>

                        <input type="radio" className="btn-check" name="btnradio" id="sempa" onClick={this.onValueChange} autoComplete="off" />
                        <label className="btn btn-primary" htmlFor="sempa">{this.props.t("This week")}</label>

                        <input type="radio" className="btn-check" name="btnradio" id="mespa" onClick={this.onValueChange} autoComplete="off" />
                        <label className="btn btn-primary" htmlFor="mespa">{this.props.t("This month")}</label>

                        <button
                          type="button"
                          className="btn btn-light mb-2"
                          onClick={this.toggle_modal}
                        >
                          <i className="bx bx-filter  font-size-22 align-middle "></i>

                        </button>
                      </div>

                    </Col>

                  </div>
                </Col>


                <Modal
                  isOpen={this.state.modal1}
                  toggle={this.toggle_modal}
                  id="event-modal"
                >
                  <ModalHeader toggle={this.toggle_modal} tag="h4">
                    {this.props.t("Custom Period")}
                  </ModalHeader>
                  <ModalBody>
                    <AvForm onValidSubmit={this.handleValidEventSubmit}>
                      <Row form>
                        <Col className="col-12 mb-3">

                          {/* <AvField
name="title"
label="Vehicles"
type="select"
onChange={this.handleSelectVehicle}
validate={{
required: { value: true },
}}

>
{vcList.map((e, key) => {

return <option key={key} value={e.id}>{e.label}</option>;

})}</AvField> */}
                        </Col>
                        <Col className="col-12 mb-3">
                          <AvField
                            type="select"
                            name="period"
                            label={this.props.t("Select Period")}
                            defaultValue={selectedPeriod}
                            validate={{
                              required: { value: true },
                            }}
                            onChange={this.handlePeriodChange}
                          >
                            <option value="day">{this.props.t("Day")}</option>
                            <option value="month">{this.props.t("Month")}</option>
                            <option value="year">{this.props.t("Year")}</option>
                          </AvField>
                        </Col>

                        {selectedPeriod === "day" ?
                          <div className="mb-3 mt-4 row">
                            <label
                              htmlFor="example-month-input"
                              className="col-md-2 col-form-label"
                            >
                              {this.props.t("Day")}
                            </label>

                            <div className="col">
                              <AvField
                                className="form-control text-sm-start"
                                name="date"
                                type="date"
                                onChange={this.dateChanged}
                                id="example-date-input"
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </div>
                          </div>
                          : null}
                        {selectedPeriod === "month" ?
                          <div className="mb-6 row mb-3">
                            <label
                              htmlFor="example-month-input"
                              className="col-md-2 col-form-label"
                            >
                              {this.props.t("Month")}
                            </label>
                            <div className="col">
                              <AvField
                                name="month"
                                className="form-control"
                                type="month"
                                onChange={this.monthChanged}
                                id="example-month-input"
                                validate={{
                                  required: { value: true },
                                }}
                              />
                            </div>
                          </div>
                          : null}
                        {selectedPeriod === "year" ?
                          <div className="mb-3 row mb-3">
                            <AvField
                              type="select"
                              name="year"
                              label={this.props.t("Select year")}
                              defaultValue={currentYear}
                              validate={{
                                required: { value: true },
                              }}
                            >
                              <option value={currentYear}>{currentYear}</option>
                              <option value={currentYear - 1}>{currentYear - 1}</option>
                              <option value={currentYear - 2}>{currentYear - 2}</option>
                              <option value={currentYear - 3}>{currentYear - 3}</option>
                              <option value={currentYear - 4}>{currentYear - 4}</option>
                            </AvField>
                          </div>
                          : null}
                      </Row>
                      {/* <h5 className="text-center mt-4">
{this.state.selectedDate || this.state.selectedMonth}
</h5> */}
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-light"
                          data-dismiss="modal"
                          onClick={this.toggle_modal}
                        >
                          {this.props.t("Close")}
                        </button>

                        <button
                          type="submit"
                          className="btn btn-success"
                        >
                          {this.props.t("Confirm")}
                        </button>
                      </div>
                    </AvForm>
                  </ModalBody>


                </Modal>

              </Row>

            </Row>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory()}
                      keyField='id'
                      columns={(this.state.InssuranceColumns || [])}
                      data={(inss || [])}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={inss}
                          columns={(this.state.InssuranceColumns || [])}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2" class="noPrint">
                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>

                                <Col sm="8" >

                                  <div className="text-sm-end" >
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={this.handleInssuranceClicks}
                                    >
                                      <i className="mdi mdi-plus me-1" />{" "}
                                      {this.props.t("New Inssurance")}
                                    </Button>

                                    <Link to="#"

                                    >

                                      {/*  <i className="mdi mdi-file-export-outline font-size-18" /> */}
                                      <td>
                                        <UncontrolledDropdown>
                                          <DropdownToggle href="#" className="card-drop" tag="a">
                                            <i className="mdi mdi-dots-vertical font-size-18" />
                                          </DropdownToggle>
                                          <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem href="#" onClick={() => inss.length == 0 ? this.setState({ no_data: true }) : exportPDF()}>
                                              <i className="mdi mdi-file-pdf-box font-size-16 text-danger me-1" />{" "}
                                              {this.props.t("Export PDF")}

                                            </DropdownItem>
                                            <DropdownItem href="#" onClick={() => inss.length == 0 ? this.setState({ no_data: true }) : ExportToExcel()} >

                                              <i className="mdi mdi-microsoft-excel font-size-16 text-success me-1" />{" "}
                                              {this.props.t("Export Excel")}
                                            </DropdownItem>
                                            <DropdownItem href="#" onClick={() => inss.length == 0 ? this.setState({ no_data: true }) : printPDF()}>
                                              <i className="mdi mdi-printer font-size-16 text-grey me-1" />{" "}
                                              {this.props.t("Print")}
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>

                                      </td>
                                    </Link>
                                  </div>
                                </Col>
                              </Row>
                              <div className="table-responsive">
                                <BootstrapTable

                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  responsive
                                  defaultSorted={defaultSorted}
                                  bordered={false}
                                  striped={false}
                                  noDataIndication={emptyDataMessage}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                />
                                <Modal
                                  isOpen={this.state.modalDenied}
                                  role="alert"
                                  autoFocus={true}
                                  data-toggle="modalDenied"
                                  centered
                                  size="md"
                                >
                                  <ModalHeader toggle={this.toggleDenied} tag="h4">     </ModalHeader>

                                  <Row className="justify-content-center">
                                    <Col >

                                      <div className="p-2 mt-4 mb-0">
                                        <div className="text-center">
                                          <div className="avatar-md mx-auto">
                                            <div className="avatar-title rounded-circle bg-light">
                                              <i className="mdi mdi-shield-lock-outline h1 mt-4 text-primary mdi-48px"></i>
                                            </div>
                                          </div>
                                          <div className="p-2 mt-3 mb-0">
                                            <h4>{this.props.t("Permission Denied !")}</h4>
                                            <br />
                                            <p>


                                              {this.props.t("You do not have the required permissions to perform this action.")}<br /> {this.props.t("Please contact the administrator for assistance.")}

                                            </p>

                                          </div>
                                        </div>
                                      </div>

                                    </Col>
                                  </Row>
                                </Modal>
                                <Modal
                                  isOpen={this.state.modal}
                                  className={this.props.className}
                                >
                                  <ModalHeader toggle={this.toggle} tag="h4">
                                    {!!isEdit ? this.props.t("Edit Inssurance") : null}
                                    {!!this.state.isShow ? this.props.t("Inssurance Infos") : null}
                                    {!isEdit && !this.state.isShow ? this.props.t("Add Inssurance") : null}
                                  </ModalHeader>
                                  <ModalBody>
                                    {this.state.submitLoading ?
                                      <Col>
                                        <div>
                                          <div >
                                            <div className="spinner-chase">
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                            </div>
                                          </div>
                                        </div>
                                        <br />
                                        <h6 className='text-center'>{this.props.t("Uploading data ...")}</h6>
                                      </Col>

                                      :

                                      <AvForm
                                        onValidSubmit={
                                          this.handleValidInssuranceSubmit
                                        }
                                        disabled={this.state.isShow ? true : false}
                                      >
                                        {/* <Row>
                                          <Col md="12">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor="validationCustom01">
                                                {this.props.t("Label")}
                                              </Label>
                                              <AvField
                                                name="label"
                                                placeholder={this.props.t("Label")}
                                                type="text"
                                                errorMessage={this.props.t("Enter Label")}
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                value={this.state.inssurance.label || ""}

                                              />
                                            </FormGroup>
                                          </Col>

                                        </Row> */}

                                        <Row>

                                          <Col md="6">
                                            <FormGroup className="mb-3">
                                              <div className="mb-3 select2-container">
                                                <Label>{this.props.t("Vehicle")}</Label>
                                                {!isEdit && !this.state.isShow ?
                                                  <div>
                                                    <AvField
                                                      name="vehicle"
                                                      type="select"
                                                      placeholder={""}
                                                      //onChange={this.handleSelectVehicle}
                                                      //options={vcList}
                                                      value={this.state.inssurance.vehicle}
                                                      disabled={this.state.isShow || !!isEdit ? true : false}
                                                      errorMessage={this.props.t("Select a Vehicle")}
                                                      className="form-control"

                                                      validate={{
                                                        required: { value: true },
                                                      }}
                                                    >

                                                      {vcList.map((e, key) => {
                                                        if (key == 0) {
                                                          return <option key={key} value="" disabled>{this.props.t("vehicle")}</option>;
                                                        } else {
                                                          return <option key={key} value={e.id}>{e.label}</option>;
                                                        }
                                                      })}
                                                    </AvField>
                                                    <Link to="/vehicles" target="_blank" rel="noopener noreferrer">{this.props.t("Add new Vehicle")}</Link>
                                                  </div>
                                                  :
                                                  <AvField
                                                    name="vehicle"
                                                    type="text"
                                                    disabled={this.state.isShow || !!isEdit ? true : false}
                                                    value={this.state.inssurance.vehicle || ""}
                                                  />
                                                }                                    </div>
                                            </FormGroup>
                                          </Col>
                                          <Col md="6">
                                            <FormGroup className="mb-3">
                                              <div className="mb-3 select2-container">
                                                <Label>{this.props.t("Inssurance company")}</Label>
                                                <AvField
                                                  name="inssurance_company"
                                                  placeholder={this.props.t("Inssurance company")}
                                                  type="text"
                                                  errorMessage={this.props.t("Enter Company name")}
                                                  className="form-control"
                                                  validate={{ required: { value: true } }}
                                                  value={this.state.inssurance.inssurance_company || ""}

                                                />
                                              </div>
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col md="6">
                                            <FormGroup className="mb-4">
                                              <Label> {this.props.t("Start date")}</Label>
                                              <AvField
                                                name="start_date"
                                                placeholder={this.props.t("Start date")}
                                                type="date"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={Moment(this.state.inssurance.start_date).format('YYYY-MM-DD') || ""}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md="6">
                                            <FormGroup className="mb-4">
                                              <Label> {this.props.t("End date")}</Label>
                                              <AvField
                                                name="end_date"
                                                placeholder={this.props.t("End date")}
                                                type="date"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={Moment(this.state.inssurance.end_date).format('YYYY-MM-DD') || ""}
                                              />
                                            </FormGroup>
                                          </Col>

                                        </Row>
                                        <Row>
                                          <Col md="5">
                                            <FormGroup className="mb-3">
                                              <div className="mb-3 select2-container">
                                                <Label>{this.props.t("Type")}</Label>
                                                <Select
                                                  name="type"
                                                  //placeholder={this.state.inssurances.type}
                                                  onChange={this.handleSelectType}
                                                  options={optionType}
                                                  value={selectedType}
                                                  isDisabled={this.state.isShow ? true : false}

                                                />
                                              </div>
                                            </FormGroup>
                                          </Col>
                                          <Col md="3">
                                            <FormGroup className="mb-3">
                                              <AvField
                                                name="amount"
                                                label={this.props.t("Amount")}
                                                placeholder={this.props.t("Total amount")}
                                                errorMessage={this.props.t("Enter Amount")}
                                                type="number"
                                                validate={{ required: { value: true } }}
                                                value={this.state.inssurance.amount || ""}
                                              />
                                            </FormGroup>

                                          </Col>
                                          <Col md="4">
                                            <FormGroup className="mb-4">
                                              <Label> {this.props.t("Payment date")}</Label>
                                              <AvField
                                                name="payment_date"
                                                placeholder={this.props.t("Payment date")}
                                                type="date"
                                                validate={{
                                                  required: { value: true },
                                                }}
                                                value={Moment(this.state.inssurance.payment_date).format('YYYY-MM-DD') || ""}
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>

                                        <Row>
                                          <Col md="12">

                                            <FormGroup className="mb-3">
                                              <Label htmlFor="validationCustom03">
                                                {this.props.t("Notes")}
                                              </Label>
                                              <AvField
                                                name="notes"
                                                placeholder={this.props.t("Notes")}

                                                type="textarea"
                                                rows="2"
                                                value={this.state.inssurance.notes || ""}
                                              />
                                            </FormGroup>
                                          </Col>


                                        </Row>

                                        {this.state.isShow ? null :
                                          <Row>
                                            <Col>
                                              <div className="text-end">

                                                <button
                                                  type="submit"
                                                  className="btn btn-success save-user"
                                                >
                                                  {this.props.t("Save")}
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                        }

                                      </AvForm>
                                    }
                                  </ModalBody>
                                </Modal>


                                {this.state.isDelete ? (
                                  <SweetAlert
                                    title={this.props.t("Are you sure?")}
                                    warning
                                    showCancel
                                    confirmButtonText={this.props.t("Yes, delete it!")}
                                    confirmBtnBsStyle="success"
                                    cancelBtnBsStyle="danger"
                                    onConfirm={() =>
                                      this.handleDeleteInssurance(vc)
                                    }
                                    onCancel={() =>
                                      this.setState({
                                        isDelete: false,
                                      })
                                    }
                                  >
                                    {this.props.t("You won't be able to revert this!")}
                                  </SweetAlert>
                                ) : null}
                                {this.state.no_data ? (
                                  <SweetAlert
                                    title={this.props.t("No data!")}
                                    warning
                                    onConfirm={() => this.setState({ no_data: false })}
                                  >
                                    {this.props.t("There is no data to export")}
                                  </SweetAlert>
                                ) : null}

                              </div>

                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
      </React.Fragment>
    )
  }
}

Inssurances.propTypes = {
  inssurances: PropTypes.array,
  vehicles: PropTypes.array,
  onGetInssurance: PropTypes.func,
  onAddNewInssurance: PropTypes.func,
  onDeleteInssurance: PropTypes.func,
  onUpdateInssurance: PropTypes.func,
  onGetVehicle: PropTypes.func,
  className: PropTypes.any,
  t: PropTypes.any,
}

const mapStateToProps = state => ({
  inssurances: state.inssurance.inssurances,
  vehicles: state.vehicle.vehicles,
})

const mapDispatchToProps = dispatch => ({
  onGetInssurance: () => dispatch(getInssurance()),
  onGetVehicle: () => dispatch(getVehicle()),
  onAddNewInssurance: inssurance => dispatch(addNewInssurance(inssurance)),
  onUpdateInssurance: inssurance => dispatch(updateInssurance(inssurance)),
  onDeleteInssurance: inssurance => dispatch(deleteInssurance(inssurance)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Inssurances)))
